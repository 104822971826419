export const navigateToPage = (page: string) => {
    window.location.href = page;
};

export const refreshPage = () => {
    window.location.reload();
};

export const scrollToTop = () => {
    window.scrollTo(0, 0);
};

export const parseQueryString = (): any => {
    const queryString = window.location.search.substring(1);
    const params: any = { hash: '' };
    const queries = queryString.split('&');

    queries.forEach((indexQuery: string) => {
        const indexPair = indexQuery.split('=');

        const queryKey = decodeURIComponent(indexPair[0]);
        const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');

        params[queryKey] = queryValue;
    });

    if (window.location.hash && window.location.hash.length > 1) {
        params.hash = window.location.hash.substring(1);
    }

    return params;
};

export const updateURLParameters = (parameters: string) => {
    const newurl: string = parameters.length > 0 ?
        `${window.location.protocol}//${window.location.host}${window.location.pathname}?${parameters}`
        : `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.pushState({ path: newurl }, '', newurl);
};

export const updateURLParameter = (name: string, v: any) => {
    const params = parseQueryString();
    const value: string = v.toString();
    params[name] = value;
    let parameters: string = '';
    for (const p of Object.keys(params)) {
        if (p !== 'hash' && p !== '') {
            if (p !== name || value.length > 0) {
                parameters = `${parameters}${parameters.length > 0 ? '&' : ''}${p}=${params[p]}`;
            }
        }
    }
    if (params.hash) {
        parameters = `${parameters}#${params.hash}`;
    }
    updateURLParameters(parameters);
};

export const getUrl = (url: string, parameters: Record<string, any>): string => { //TODO: move to core
    if (!parameters) { return url; }

    const params = Object.keys(parameters)
        .filter(key => parameters[key])
        .map(key => `${key}=${parameters[key]}`)
        .join('&');

    return `${url}?${params}&MaxResultCount=1000`;
}