import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultUser, getUserForSave, IUser } from '../entities/User';

export const useGetUsers = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IUser>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/identity/users?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetCurrentUser = () => {
    const [service, setService] = useState(GetRequest.empty<IUser>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/site-settings/current-user`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutUser = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [user, setUser] = useState<IUser>(defaultUser);

    useEffect(() => {
        if (user.id === '') { return; }
        processPutAsync(setService, `/api/identity/users/${user.id}`, getUserForSave(user));
        setUser(defaultUser);
    }, [user]);

    return { service, setUser };
};

export const useGetUserByUsername = () => {
    const [service, setService] = useState(GetRequest.empty<IUser>());
    const [username, setUserName] = useState<string>('');

    useEffect(() => {
        if (username === '') { return; }
        processGetAsync(setService, `/api/identity/users/by-username/${username}`);
        setUserName('');
    }, [username]);

    return { service, setUserName };
};
