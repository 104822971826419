import React, { useEffect, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { Icon } from '../../../core/entities/Icon';
import { parseQueryString } from '../../../core/utilities/URL';
import { IReportListItem, defaultReportListItem } from '../../../reporting/entities/Report';
import { defaultReportAccessLog } from '../../../reporting/entities/ReportAccessLog';
import { ISession, defaultSession, getSession, saveSession } from '../../../reporting/entities/Session';
import { useCreateSession } from '../../../reporting/services/ReportServerService';
import { useGetReport, useLogAccess } from '../../../reporting/services/ReportService';

const MSIframe: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const { service: reportService, setItemId: setReportId } = useGetReport();
    const { service: sessionService, setItem: setSessionItem } = useCreateSession();
    const { setItem: logReport } = useLogAccess();

    const [reportId] = useState(params.id);
    const [showTitle, setShowTitle] = useState<boolean>(params.title !== '0');
    const [item, setItem] = useState<IReportListItem>(defaultReportListItem);
    const [session, setSession] = useState<ISession>(defaultSession);
    const [url, setUrl] = useState<string>('');
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        if (reportService.result) {
            logReport({ ...defaultReportAccessLog, reportId: reportService.result.report.id });
            setItem(reportService.result);
        }
        // eslint-disable-next-line
    }, [reportService]);

    useEffect(() => {
        if (sessionService.isFinished) {
            setSession(sessionService.data.data);
        }
    }, [sessionService]);

    useEffect(() => {
        setReportId(reportId);
        // eslint-disable-next-line
    }, [reportId]);

    useEffect(() => {
        if (item.report.id !== '') {
            setSessionItem(getSession(item.session));
        }
        // eslint-disable-next-line
    }, [item]);

    useEffect(() => {
        if (session.username && session.username.length > 0) {
            if (session.success) {
                saveSession(session);
                setUrl(`${url}&connMode=1&usrSmgr=${session.sessionId}`);
            }
            else {
                setErrors([session.errorMessage]);
            }
        }
        // eslint-disable-next-line
    }, [session]);

    useEffect(() => {
        let url: string = '';
        for (const p of item.reportParameters) {
            if (p.key === 'title') {
                setShowTitle(p.value === "1");
            }
            else {
                url = `${url}${url.length > 0 ? '&' : '?'}${p.key}=${p.value}`;
            }
        }
        if (item.report.externalId !== '') {
            url = `${url}${url.length > 0 ? '&' : '?'}documentId=${item.report.externalId}`;
        }
        setUrl(`${item.reportServer.path}${url}`);
    }, [item]);


    return (
        <>
            <OESpinner message="Loading Report" hidden={!reportService.isInProgress && !sessionService.isInProgress} />
            {!reportService.isInProgress && !sessionService.isInProgress && (
                <>
                    {showTitle && (
                        <OEHeading className="m-b-20 m-t-10" text={item.report.title} icon={Icon.ReportAdministration} />
                    )}
                    <OEErrorList errors={errors} />
                    {session.success && (
                        <>
                            <iframe
                                style={{
                                    boxSizing: 'border-box',
                                    display: 'block !important;',
                                    width: `${item.report.width > 0 ? `${item.report.width}px` : '100%'}`,
                                    height: `${item.report.height > 0 ? `${item.report.height.toString()}px` : `calc(100vh - ${showTitle ? '240' : '200'}px`}`
                                }}
                                title={item.title}
                                src={url}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default MSIframe;

