import React from 'react';
import OEButton, { ButtonStyle } from '../form/OEButton';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';

interface IComponentInfo {
    onSubmit: (e: any) => void;
    submitText?: string;
    cancelText?: string;
    onCancel?: (e: any) => void;
    disabled?: boolean;
    sm: number;
    smOffset?: number;
    altActionText?: string;
    onAltAction?: (e: any) => void;
}

const OEFormSubmit: React.FunctionComponent<IComponentInfo> = ({
    onSubmit,
    onCancel,
    disabled,
    sm,
    smOffset,
    submitText,
    cancelText,
    altActionText,
    onAltAction,
}) => {
    return (
        <OERow className="m-b-10">
            <OECol sm={sm} smOffset={smOffset}>
                <OEButton
                    onClick={onSubmit}
                    disabled={disabled}
                    text={submitText || 'Submit'}
                />

                {onCancel && (
                    <OEButton
                        bStyle={ButtonStyle.Cancel}
                        disabled={disabled}
                        onClick={onCancel}
                        text={cancelText || 'Cancel'}
                    />
                )}

                {altActionText && (
                    <OEButton
                        bStyle={ButtonStyle.Secondary}
                        disabled={disabled}
                        onClick={onAltAction}
                        text={altActionText}
                    />
                )}
            </OECol>
        </OERow>
    );
};

export default OEFormSubmit;
