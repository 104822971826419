import React, { Children } from 'react';
import {
    getFormControlTouched,
    getFormValue
} from '../../utilities/FormHelper';
import { OEFormLabel } from '../form/OEForm';
import OELink from '../general/OELink';
import { OECol } from '../grid/OECol';
import { OERow } from '../grid/OERow';
import OEFormError from './OEFormError';

export interface IOEFormItemInfo {
    columns: number;
    name: string;
    label: string;
    labelInstructions?: string;
    required?: boolean;
    errors?: any;
    touched?: any;
    onChange?: any;
    setFieldValue?: any;
    disabled?: boolean;
    hidden?: boolean;
    autoFocus?: boolean;
    autoComplete?: string;
    action?: any;
    actionIcon?: string;
    children?: any;
}

const OEFormItem: React.FC<IOEFormItemInfo> = ({
    columns,
    name,
    label,
    labelInstructions,
    errors,
    touched,
    required,
    hidden,
    children,
    action,
    actionIcon
}) => {
    const labelColSpan: number = columns;
    const inputColSpan: number = (columns === 12 ? 12 : 12 - columns) - (actionIcon ? 1 : 0);

    return (
        <>
            {!hidden && (
                <OERow>
                    {columns > 0 && (
                        <OECol
                            sm={labelColSpan}
                            xs={labelColSpan}
                            md={labelColSpan}
                            lg={labelColSpan}
                        >
                            <OEFormLabel required={required} htmlFor={name}>
                                {label}
                                {labelInstructions && (
                                    <div className="m-t-0 small" ><i>{labelInstructions}</i></div>
                                )}
                            </OEFormLabel>
                            <br />
                        </OECol>
                    )}

                    <OECol
                        sm={inputColSpan}
                        xs={inputColSpan}
                        md={inputColSpan}
                        lg={inputColSpan}
                    >
                        {Children.map(children, (i) => i)}
                        {errors && touched && (
                            <OEFormError
                                error={getFormValue(errors, name)}
                                touched={getFormControlTouched(touched, name)}
                            />
                        )}
                    </OECol>
                    {action && (
                        <OECol sm={1} >
                            <OELink icon={actionIcon} onClick={action} />
                        </OECol>
                    )}
                </OERow>
            )}
        </>
    );
};

export default OEFormItem;
