import React, { CSSProperties, Fragment } from 'react';

/// -----------------------------------------------
/// Spinner Component
/// -----------------------------------------------

export enum SpinnerStyle {
    Large = 'fas fa-spinner fa-pulse fa-2x fa-fw',
    Small = 'fas fa-spinner fa-pulse fa-fw',
    ExtraSmall = 'fas fa-spinner fa-pulse fa-xs fa-fw'
}

interface IComponentInfo {
    className?: string;
    message?: string;
    hidden?: boolean;
    oeStyle?: string;
}

const iconDiv: CSSProperties = {
    verticalAlign: 'middle'
};

const mainDiv: CSSProperties = {
    margin: '5px',
    padding: '5px',
};

const OESpinner: React.FunctionComponent<IComponentInfo> = ({ message, hidden, className, oeStyle }) => (
    <Fragment>
        {message !== '' && !hidden && (
            <div style={mainDiv} className={className}>
                <i className={oeStyle || SpinnerStyle.Large} style={iconDiv} />
                <strong>
                    {message && (
                        <Fragment>{message}...</Fragment>
                    )}

                    {!message && (
                        <Fragment>Loading...</Fragment>
                    )}
                </strong>
            </div>
        )}
    </Fragment>
);

export default OESpinner;