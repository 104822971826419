import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { defaultExtraProperties, IExtraProperties } from "../../core/entities/ExrtraProperties";

export interface IProfile extends IBaseAuditedEntity {
    userName: string;
    email: string;
    emailConfirmed: boolean;
    name: string;
    surname: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    isExternal: boolean;
    hasPassword: boolean;
    extraProperties: IExtraProperties;
}

export const defaultProfile: IProfile = {
    userName: '', email: '', emailConfirmed: false,
    name: '', surname: '', phoneNumber: '', phoneNumberConfirmed: false, isExternal: false,
    hasPassword: false, extraProperties: defaultExtraProperties
};
