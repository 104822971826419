import React, { useEffect, useState } from 'react';
import { MultiSelect, Option } from 'react-multi-select-component';
// https://www.npmjs.com/package/react-multi-select-component
import { IDropdownValues } from './OEDropdown';

export interface IMultiSelectLocalization {
    allItemsAreSelected: string;
    clearSearch: string;
    clearSelected: string;
    noOptions: string;
    search: string;
    selectAll: string;
    selectAllFiltered: string;
    selectSomeItems: string;
    create: string;
}

export const defaultMultiSelectLocalization = {
    allItemsAreSelected: 'All items are selected.',
    clearSearch: 'Clear Search',
    clearSelected: 'Clear Selected',
    noOptions: 'No options',
    search: 'Search',
    selectAll: 'Select All',
    selectAllFiltered: 'Select All (Filtered)',
    selectSomeItems: 'Select...',
    create: 'Create'
};

interface IComponentInfo {
    value: string;
    name?: string;
    values: IDropdownValues[];
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    placeholder?: string;
    onChange?: (content: any) => void;
    setFieldValue?: (id: any, data: any) => void;
    localization?: IMultiSelectLocalization;
}

const OEMultiSelect: React.FunctionComponent<IComponentInfo> = ({
    value, values, className, onChange, name, setFieldValue, localization, disabled }) => {

    const [options, setOptions] = useState<Option[]>([]);
    const [selected, setSelected] = useState<Option[]>([]);
    const [localValues, setLocalValues] = useState<IDropdownValues[]>([]);
    const [multiSelectOpen, setMultiSelectOpen] = useState<boolean>(false);
    const [applyFilter, setApplyFilter] = useState<boolean>(false);
    const [settings, setSettings] = useState<any>(defaultMultiSelectLocalization);

    useEffect(() => {
        if (values.length > 0 && JSON.stringify(values) !== JSON.stringify(localValues)) {
            setLocalValues(values);
            const v: string[] = value.split(',');

            // console.log('values->', values);
            const o: Option[] = [];
            const s: Option[] = [];
            for (const c of values) {
                o.push({ value: c.id, label: c.name })
                if (value === '' || v.length === 0 || v.find(q => q.toLowerCase().trim() === c.id.toLowerCase())) {
                    s.push({ value: c.id, label: c.name })
                }
            }
            setOptions([...o]);
            setSelected([...s]);
            const d: IMultiSelectLocalization = localization || defaultMultiSelectLocalization;
            setSettings({
                "allItemsAreSelected": d.allItemsAreSelected,
                "clearSearch": d.clearSearch,
                "clearSelected": d.clearSelected,
                "noOptions": d.noOptions,
                "search": d.search,
                "selectAll": d.selectAll,
                "selectAllFiltered": d.selectAllFiltered,
                "selectSomeItems": d.selectSomeItems,
                "create": d.create,
            });
        }
        //eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        setApplyFilter(!multiSelectOpen);
        // eslint-disable-next-line
    }, [multiSelectOpen]);

    useEffect(() => {
        const v: string[] = value.split(',');
        const o: Option[] = [];
        const s: Option[] = [];
        for (const c of values) {
            o.push({ value: c.id, label: c.name })
            if (value === '' || v.length === 0 || v.find(q => q.toLowerCase().trim() === c.id.toLowerCase())) {
                s.push({ value: c.id, label: c.name })
            }
        }
        setOptions([...o]);
        setSelected([...s]);
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (applyFilter) {
            let s: string = '';
            for (const v of selected) {
                s = `${s}${s.length > 0 ? ',' : ''}${v.value}`;
            }
            name && setFieldValue?.(name, s);
            onChange?.(s);
        }
        setApplyFilter(false);
        // eslint-disable-next-line
    }, [applyFilter]);

    useEffect(() => {
        if (!multiSelectOpen) {
            setApplyFilter(true);
        }
        // eslint-disable-next-line
    }, [selected]);

    const onMenuToggle = (i: boolean) => {
        setMultiSelectOpen(i);
    }

    const onSelected = (i: Option[]) => {
        setSelected(i);
    }

    return (
        <>
            <div className={className} >
                <MultiSelect
                    disabled={disabled}
                    className="multi-select"
                    onMenuToggle={onMenuToggle}
                    hasSelectAll={true}
                    ClearSelectedIcon={null}
                    disableSearch={values.length < 10}
                    options={options}
                    value={selected}
                    overrideStrings={settings}
                    onChange={onSelected}
                    labelledBy="Select"

                />
            </div>
        </>
    );
};

export default OEMultiSelect;
