import { IMicroStrategyDossierPage } from "./MicrostrategyDossierPage";

export interface IMicroStrategyDossierChapter {
    childrenAlias: string;
    currentPageIndex: number;
    index: number;
    link: string;
    name: string;
    nodeKey: string;
    children: IMicroStrategyDossierPage[];
    getFirstPage: () => void;
}

export interface IMicroStrategyDossierChapterList {
    chapters: IMicroStrategyDossierChapter[];
    totalPages: number;
}

export const defaultMicroStrategyDossierChapterList: IMicroStrategyDossierChapterList = {
    chapters: [], totalPages: 0
}