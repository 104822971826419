
export interface ISession extends Record<any, any> {
    sessionId: string;
    sessionInfo: string;
    url: string;
    success: boolean;
    server: string;
    port: string;
    project: string;
    errorMessage: string;
    username: string;
}

export const defaultSession: ISession = {
    sessionId: '', sessionInfo: '', url: '', success: false, server: '', port: '',
    project: '', errorMessage: '', username: '', uri: ''
};

const sessionKey = (s: ISession) => {
    return `${s.server}:${s.project}`;
}

export const getSession = (s: ISession): ISession => {
    try {
        s.sessionInfo = localStorage.getItem(sessionKey(s)) || '';
    }
    catch {
    }
    return s;
}

export const saveSession = (s: ISession) => {
    localStorage.setItem(sessionKey(s), s.sessionId);
}
