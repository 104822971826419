import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { DeleteRequest, processDeleteAsync } from '../../core/services/DeleteRequest';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultReportPage, IReportPage } from '../entities/ReportPage';

export const useGetReportPage = (id: string) => {
    const [service, setService] = useState(GetRequest.empty<IReportPage>());
    const [pageId, setPageId] = useState<string>(id);

    useEffect(() => {
        if (pageId === '') { return; }
        processGetAsync(setService, `/api/app/report-pages/${pageId}`);
        setPageId('');
    }, [pageId]);

    return { service, setPageId };
};

export const useGetReportPageTiered = (id: string) => {
    const [service, setService] = useState(GetRequest.empty<IReportPage>());
    const [pageId, setPageId] = useState<string>(id);

    useEffect(() => {
        if (pageId === '') { return; }
        processGetAsync(setService, `/api/app/report-pages/${pageId}/tiered`);
        setPageId('');
    }, [pageId]);

    return { service, setPageId };
};

export const useGetReportPagePublic = (id: string) => {
    const [service, setService] = useState(GetRequest.empty<IReportPage>());
    const [pageId, setPageId] = useState<string>(id);

    useEffect(() => {
        if (pageId === '') { return; }
        processGetAsync(setService, `/api/app/report-pages/${pageId}/public`);
        setPageId('');
    }, [pageId]);

    return { service, setPageId };
};

export const useGetReportPages = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportPage>>());
    const [itemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (itemId === '') { return; }
        processGetAsync(setService, `/api/app/report-pages?MaxResultCount=1000&PageType=${itemId}`);
        setItemId('');
    }, [itemId]);

    return { service, setItemId };
};

export const usePutReportPage = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IReportPage>(defaultReportPage);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/report-pages/${item.id}`, item);
        setItem(defaultReportPage);
    }, [item]);

    return { service, setItem };
};

export const useCreateReportPage = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [item, setItem] = useState<IReportPage>(defaultReportPage);

    useEffect(() => {
        if (item.name === '') { return; }
        processPostAsync(setService, `/api/app/report-pages`, item);
        setItem(defaultReportPage);
    }, [item]);

    return { service, setItem };
};

export const useDeleteReportPage = () => {
    const [service, setService] = useState(DeleteRequest.empty());
    const [imemId, setItemId] = useState<string>('');

    useEffect(() => {
        if (imemId === '') { return; }
        processDeleteAsync(setService, `/api/app/report-pages/${imemId}`);
        setItemId('');
    }, [imemId]);

    return { service, setItemId };
};


