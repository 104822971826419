/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { IReportGroup } from '../../../reporting/entities/ReportGroup';
declare global { var msLogin: any; }

interface IComponentInfo {
    item: IReportGroup;
    currentIndex: number;
    aboutIndex: number;
    setAboutIndex: (i: number) => void;
}

const MicrostrategyPageAbout: React.FunctionComponent<IComponentInfo> = ({ item, setAboutIndex, currentIndex, aboutIndex }) => {

    const onSelect = () => {
        setAboutIndex(currentIndex);
    }

    return (
        <button onClick={onSelect} id={`reports-tab-group`} role="tab" data-rb-event-key={`group`}
            aria-controls={`reports-tabpane-group`} aria-selected="true"
            className={`nav-item nav-link ${currentIndex === aboutIndex ? 'active' : ''}`}>
            {item.title}
        </button>
    );
};

export default MicrostrategyPageAbout;

