import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../core/components/messaging/entities/ConfirmationMessage';
import { defaultNotification, INotification } from '../../core/components/messaging/entities/Notification';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import OEConfirmation from '../../core/components/messaging/OEConfirmation';
import OENotification from '../../core/components/messaging/OENotification';
import { getCookie, reactLogout } from '../../core/services/Authentication';
import { useLogout } from '../../security/services/SecurityService';
import { ISiteSettings } from '../entities/SiteSettings';
declare global { var msLogout: any; }

interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
}

export function SessionController({ settings, logout, children }: { settings: ISiteSettings, logout: boolean, children: ReactNode }) {
    const { service: logoutService, logout: processLogout } = useLogout();

    const timerIdRef = useRef<number>(0);
    const [timer, setTimer] = useState<number>(0);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [sessionTimeout, setSessionTimeout] = useState<number>(0);
    const [sessionTimer, setSessionTimer] = useState<ISessionTimer>({ seconds: -1, isActive: false, initialized: false, });
    const [browserCloseDelay, setBrowserCloseDelay] = useState<number>(0);
    const [notification, setNotification] = useState<INotification>(defaultNotification);

    const resetTimer = useCallback(() => {
        if (sessionTimeout > 0 && sessionTimer.isActive) {
            const timeout: number = sessionTimeout * 60;
            var expires = new Date(Date.now() + timeout * 1000).toUTCString();
            document.cookie = `XSRF-TOKEN=${getCookie('XSRF-TOKEN')}; expires=${expires}; path=/;`;
            setTimer(timeout);
        }
    }, [sessionTimeout, sessionTimer]);

    useEffect(() => {
        const handleWindowEvents = () => {
            resetTimer();
        };

        // listen for specific window events to ensure user is still active
        window.addEventListener('mousemove', handleWindowEvents);
        window.addEventListener('keydown', handleWindowEvents);
        window.addEventListener('click', handleWindowEvents);
        window.addEventListener('scroll', handleWindowEvents);

        handleWindowEvents();

        //cleanup function
        return () => {
            window.removeEventListener('mousemove', handleWindowEvents);
            window.removeEventListener('keydown', handleWindowEvents);
            window.removeEventListener('click', handleWindowEvents);
            window.removeEventListener('scroll', handleWindowEvents);
        };
    }, [resetTimer]);

    const startTimer = () => {
        if (timerIdRef.current) {
            return;
        }
        timerIdRef.current = window.setInterval(() => setTimer((timer) => timer - 1), 1000);
    }

    useEffect(() => {
        if (settings.sessionTimeout !== '') {
            setSessionTimeout(parseInt(settings.sessionTimeout, 10));
            setBrowserCloseDelay(parseInt(settings.browserCloseDelay, 10));
            setSessionTimer({
                seconds: sessionTimeout * 60,
                isActive: true,
                initialized: false,
            });
        }
        startTimer();
    }, [settings, sessionTimeout]);

    useEffect(() => {
        if (logoutService.result !== undefined) {
            reactLogout(settings.logoutRedirect);
        }
        startTimer();
        // eslint-disable-next-line
    }, [logoutService]);

    useEffect(() => {
        if (sessionTimer.seconds > 0) {
            if (timer < 0) {
                onLogout();
            }
            else if (timer <= 30) {
                setSessionTimer({ ...sessionTimer, isActive: false });
                setConfirmation({
                    ...defaultConfirmationMessage,
                    setConfirmation, show: true,
                    title: 'Session About to Expire',
                    message: `<p><b>Your session will end in  ${timer > 0 ? timer : 0} seconds due to inactivity</b></p><p>As a security precaution, if there is no additional activity in your online session,the session will end and you will be automatically logged out</p><p>If you are still working in your online session, choose OK to continue.</p>`,
                    onOk: extendSession,
                });
            }
            if (!sessionTimer.initialized) {
                window.onbeforeunload = function () {
                    var expires = new Date(Date.now() + browserCloseDelay * 1000).toUTCString();
                    document.cookie = `XSRF-TOKEN=${getCookie('XSRF-TOKEN')}; expires=${expires}; path=/;`;
                };
                setSessionTimer({ ...sessionTimer, initialized: true });
            }
        }
        // eslint-disable-next-line
    }, [timer]);

    const onLogout = () => {
        setSessionTimer({ ...sessionTimer, isActive: false });
        notification.message === '' && setNotification({ message: SuccessMessages.Logout, type: 'success' });
        setConfirmation({ ...defaultConfirmationMessage });
        msLogout(localStorage.getItem('msPath'), localStorage.getItem('msauthurl'), processLogout);
    };

    useEffect(() => {
        logout && onLogout();
        // eslint-disable-next-line
    }, [logout]);

    useEffect(() => {
        if (logoutService.result !== undefined) {
            reactLogout(settings.logoutRedirect);
        }
        startTimer();
        // eslint-disable-next-line
    }, [logoutService]);

    const extendSession = (s?: boolean) => {
        !s && setConfirmation({ ...defaultConfirmationMessage });
        setSessionTimer({ ...sessionTimer, isActive: true });
        resetTimer();
    };

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OENotification setNotification={setNotification} notification={notification} />
            {children}
        </>
    );
};
