import React, { Children } from 'react';
import OEWell, { WellStyle } from '../../core/components/general/OEWell';
import { Icon } from '../../core/entities/Icon';
import { IWidget } from '../entities/Widget';

interface IComponentInfo {
    item: IWidget;
    action: (i: string) => void;
    children?: any;
}

const PageContent: React.FunctionComponent<IComponentInfo> = ({ item, children, action }) => {
    const onEdit = () => {
        action(item.id);
    }
    return (
        <>
            {item && (
                <OEWell headingSize={6} icon={Icon.EditHeader} title={item.name} oeStyle={WellStyle.Bordered} action={onEdit} actionIcon={Icon.Edit}>
                    {Children.map(children, i => i)}
                </OEWell>
            )}
        </>
    );
};


export default PageContent;