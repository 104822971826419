import React, { useEffect, useRef, useState } from 'react';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OENotification from '../../../core/components/messaging/OENotification';
import { INotification, defaultNotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { displayHTMLContent } from '../../../core/utilities/Miscellaneous';
import { parseQueryString, updateURLParameter } from '../../../core/utilities/URL';
import { canDebugReport } from '../../../reporting/entities/Report';
import { ReportConfigurationType, getReportConfigurationValue } from '../../../reporting/entities/ReportConfiguration';
import { IReportFilter, getFiltersFromBaseFilters, getJSONFromReportFilter, getReportFilterList } from '../../entities/ReportFilter';
import { IReportGroup, ReportGroupType, defaultReportGroup } from '../../../reporting/entities/ReportGroup';
import { IReportPage, defaultReportPage } from '../../../reporting/entities/ReportPage';
import { ReportProcessingStep, reportProcessingStep, updateProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { ReportType } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetReportPageTiered } from '../../../reporting/services/ReportPageService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IDossierSettings, defaultDossierSettings } from '../../entities/MicroStrategyDossier';
import { IMSFilter, defaultMSFilter, getMSFilters, getReportFilters } from '../../entities/MicroStrategyFilters';
import Filters from '../report-common/Filters';
import Report from '../report-common/Report';
import TokenValidation from '../report-common/TokenValidation';
import SectionGroup from './SectionGroup';

declare global { var runCode: any; }
declare global { var microstrategy: any; }

const MSEmbeddedTierOne: React.FunctionComponent = () => {
    const params: any = parseQueryString();
    const [pageParameterId] = useState(params['pid'] || '');
    const [debug] = useState<boolean>(canDebugReport());
    const footerRef = useRef<any>();

    const { service: embeddedFilterService, doRefresh: initEmbeddedFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: pageService, setPageId } = useGetReportPageTiered(''); // Step 2 - get the page information
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 3 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 4 - get filters for page

    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [pageFilters, setPageFilters] = useState<IReportFilter[]>([]);
    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [processingStep, setProcessingStep] = useState<ReportProcessingStep>(ReportProcessingStep.CheckToken);

    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [pageSettings, setPageSettings] = useState<IDossierSettings>(defaultDossierSettings);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);
    const [notiication, setNotification] = useState<INotification>(defaultNotification);
    const [filters, setFilters] = useState<IMSFilter[]>([]);
    const [filterReport, setFilterReport] = useState<boolean>(false);
    const [hideApply, setHideApply] = useState<boolean>(false);
    const [filterInitialzied, setFilterInitialzied] = useState<boolean>(false);
    const [footer, setFooter] = useState<string>('');
    const [reportHeight, setReportHeight] = useState<number>(0);

    useEffect(() => {
        reportProcessingStep(processingStep, setNotification, debug);
        switch (processingStep) {
            case ReportProcessingStep.TokenValid:
                initEmbeddedFilters();
                break;

            case ReportProcessingStep.LoadPage:
                debug && setNotification({ message: 'Loading Page', type: 'info' });
                setPageId(pageParameterId);
                break;

            case ReportProcessingStep.PageLoaded:
                debug && setNotification({ message: 'Page Loaded', type: 'info' });
                break;

            case ReportProcessingStep.LoadGroup:
                debug && setNotification({ message: 'Loading Group', type: 'info' });
                loadGroup();
                break;

            case ReportProcessingStep.GroupLoaded:
                updateProcessingStep(ReportProcessingStep.LoadReport, setProcessingStep);
                debug && setNotification({ message: 'Group Loaded', type: 'info' });
                break;

            case ReportProcessingStep.ReportLoaded:
                updateHeight();
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);


    useEffect(() => {
        reportProcessingStep(processingStep, setNotification, debug);
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (embeddedFilterService.result) {
            updateProcessingStep(ReportProcessingStep.LoadPage, setProcessingStep);
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (pageService.result) {
            setPage(pageService.result);
        }
        // eslint-disable-next-line
    }, [pageService]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (page.id !== '') {
            updateProcessingStep(ReportProcessingStep.PageLoaded, setProcessingStep);
            if (!setGroupByID(params.gid)) {
                setGroup(page.groups[0]);
            }
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (group.id !== '') {
            setProcessingStep(ReportProcessingStep.LoadGroup);
        }
        // eslint-disable-next-line
    }, [group]);

    useEffect(() => {
        if (siteReport.id !== '') {
            const reportFilters: IReportFilter[] = [...pageFilters];
            for (const i of reportFilters) {
                if (filters.filter(q => q.name === i.name).length > 0) {
                    i.value = filters.filter(q => q.name === i.name)[0].value;
                }
            }
            setSiteReportFilter({ ...siteReport, reportFilters });
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            setSiteFilters(siteReportFilterService.data.data);
            // setFilterValues(siteReportFilterService.data.data);
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        if (siteFilters.length > 0) {
            setFilterInitialzied(true);
            filterInitialzied ? updateFilters() : initFilters();
        }
        // eslint-disable-next-line
    }, [siteFilters]);

    const loadGroup = () => {
        updateURLParameter("gid", group.id);
        initFilters();
        window.setTimeout(() => {
            setProcessingStep(ReportProcessingStep.GroupLoaded);
        }, 1000); // this setTimeout is a workaround for preloaded reports
        setFooter('');
    }

    useEffect(() => {
        if (group.id === '' && filters.length > 0) {
            !setGroupByID(params.gid) && (page.groups.length > 0 ? setGroup(page.groups[0]) : setGroup(defaultReportGroup));
        }
        window.setTimeout(() => {
            updateProcessingStep(ReportProcessingStep.LoadDossierFilters, setProcessingStep);
        }, filterInitialzied ? 1000 : 5000); // this delay is necessary to refresh cascading filters 
        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        if (siteReport.id !== '') {
            const reportFilters: IReportFilter[] = [...pageFilters];
            for (const i of reportFilters) {
                if (filters.filter(q => q.name === i.name).length > 0) {
                    i.value = filters.filter(q => q.name === i.name)[0].value;
                }
            }
            setSiteReportFilter({ ...siteReport, reportFilters });
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (siteReport.id !== '') {
            setSiteReportFilter({ ...siteReport, reportFilters: pageFilters });
        }
        // eslint-disable-next-line
    }, [siteReport]);


    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            setSiteFilters(siteReportFilterService.data.data);
            // setFilterValues(siteReportFilterService.data.data);
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    const initFilters = () => {
        setPageFilters(getFiltersFromBaseFilters(page.filters, baseFilters));
        //setGroupFilters(getFiltersFromBaseFilters(group.filters, baseFilters));
        const f: IMSFilter[] = getMSFilters(page.filters, siteFilters, baseFilters, 1);
        for (const i of getMSFilters(group.filters, siteFilters, baseFilters, 2)) {
            if (f.filter(q => q.name === i.name).length === 0) {
                f.push(i);
            }
        }
        if (group.reports.length > 0) {
            debug && console.log(getJSONFromReportFilter(group.reports[0].filters).filter(q => q.dossier));
            for (const i of getJSONFromReportFilter(group.reports[0].filters).filter(q => q.dossier)) {
                f.push({ ...defaultMSFilter, selectAllText: i.selectAllText, showSelectAll: i.showSelectAll, name: i.name, label: i.label, level: 2, dossier: true });
            }
            debug && console.log('selected report filters', group.reports[0].filters);
            debug && console.log('new filters', f);
            setFooter(getReportConfigurationValue(group.configuration, ReportConfigurationType.ReportFoooter));
        }
        setPageSettings({
            ...defaultDossierSettings, hideFilterSummary:
                getReportConfigurationValue(page.configuration, ReportConfigurationType.HideFilterSummary)
                || getReportConfigurationValue(group.configuration, ReportConfigurationType.HideFilterSummary)
        });

        setFilters(f);
        setSiteReportId(ReportType.EmbeddedFilters);
        setHideApply(getReportConfigurationValue(page.configuration, ReportConfigurationType.HideApply));
        debug && setNotification({ message: 'Setting Filters', type: 'info' });
        //setFilterReport(true);
    }

    const updateFilters = () => {
        const f: IMSFilter[] = getMSFilters(page.filters, siteFilters, baseFilters, 1);
        for (const i of getMSFilters(group.filters, siteFilters, baseFilters, 2)) {
            if (f.filter(q => q.name === i.name).length === 0) {
                f.push(i);
            }
        }
        for (const i of f.filter(q => !q.dossier)) {
            i.value = filters.filter(q => i.name === q.name).length > 0 ? filters.filter(q => i.name === q.name)[0].value : '';
        }
        const v: IMSFilter[] = [];
        for (const c of filters) {
            if (f.filter(q => q.name === c.name).length > 0) {
                v.push(f.filter(q => q.name === c.name)[0]);
            }
            else {
                if (v.filter(q => q.key === c.key).length === 0) {
                    v.push(c);
                }
            }
        }
        setFilters(v);
    }

    const updateFilterValues = (f: IMSFilter[], level: number, isCascading: boolean, dossier: boolean) => {
        debug && setNotification({ message: `Filters changed ${dossier} - ${level} ${processingStep}`, type: 'info' });
        if (level === 1) {
            setSiteReportFilter({ ...siteReport, reportFilters: getReportFilters([...f, ...filters.filter(q => q.level === 2)], siteReport) });
        }
        else {
            setSiteReportFilter({ ...siteReport, reportFilters: getReportFilters([...filters.filter(q => q.level === 1), ...f,], siteReport) });
        }
        if (processingStep === ReportProcessingStep.Finished) {
            updateProcessingStep(ReportProcessingStep.LoadDossierFilters, setProcessingStep);
        }
    }

    const setGroupByID = (id: string): boolean => {
        for (const g of page.groups) {
            if (g.id === id) {
                setGroup(g);
                return true;
            }
        }
        return false;
    }

    const updateHeight = () => {
        setReportHeight(330);
    }

    const onFilterReport = () => {
        setFilterReport(true);
    }

    const onChangeGroup = (i: IReportGroup) => {
        setReportHeight(0);
        setGroup(i);
    }

    return (
        <div className="report-embedded m-b-0 three-tier">
            <TokenValidation processingStep={processingStep} setProcessingStep={setProcessingStep} setNotification={setNotification} />
            <OENotification setNotification={setNotification} notification={notiication} />
            <div className="content" style={{ height: `calc(100vh - 145px)` }}>
                {pageParameterId && pageParameterId !== '' && (
                    <>
                        <p className="subtitle m-t-10 m-l-10">{`${page.groups.length === 1 ? `${page.name} - ${page.groups[0].title}` : page.name}`}</p>
                        <div className="cleardiv" />

                        {filters.length > 0 && (
                            <>
                                <Filters
                                    processingStep={processingStep}
                                    updateFilterValues={updateFilterValues}
                                    level={1}
                                    hideApply={hideApply}
                                    filterReport={onFilterReport}
                                    filters={filters.filter(q => !q.dossier)}
                                    setFilters={setFilters}
                                />
                                <div className="cleardiv m-b-10" />
                            </>
                        )}
                        {page.groups.length > 0 && (
                            <>
                                {page.groups.filter(q => q.isActive && q.groupType !== ReportGroupType.AboutPage).length > 1 && (
                                    <nav className="fst-italic small m-b-0 nav nav-tabs mb-3 nav nav-tabs" role="tablist">
                                        {page.groups.filter(q => q.isActive).map((g, index) =>
                                            <SectionGroup key={index} index={index} group={g} selected={group.id === g.id} onClick={onChangeGroup} />
                                        )}
                                    </nav>
                                )}
                                {group.id !== '' && (
                                    <div style={{ height: `calc(100vh - 330px)` }}>
                                        {group.reports.length > 0 && (
                                            <Report
                                                processingStep={processingStep}
                                                setProcessingStep={setProcessingStep}
                                                reportId={group.reports[0].reportId}
                                                reportConfiguration={group.reports[0].configuration}
                                                divId={`report1`}
                                                setNotification={setNotification}
                                                filters={filters}
                                                setFooter={setFooter}
                                                filterReport={filterReport}
                                                setFilterReport={setFilterReport}
                                                minSize={300}
                                                pageSettings={pageSettings}
                                                reportHeight={reportHeight}
                                                onFilterReport={onFilterReport}
                                                index={0}
                                                aboutPages={page.groups.filter(q => q.isActive && q.groupType === ReportGroupType.AboutPage)}
                                            />
                                        )}
                                        {footer && (
                                            <div className="footer" ref={footerRef} dangerouslySetInnerHTML={displayHTMLContent(footer)} />
                                        )}

                                        {group.reports.length === 0 && (
                                            <OEMessage className="h5" type={MessageType.Danger} hideDismissable={true} message={`No report have been set up for ${group.title} `} />
                                        )}
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div >
    );
};

export default MSEmbeddedTierOne;
