import { redirectLogin } from '../services/Authentication';

export const getAPIError = (e: any): string => {
    try {
        let error: string = '';
        let data: any;
        let code: string = '';
        if (e.response.status === 401) {
            redirectLogin();
        }
        else {
            try {
                if (e.response && e.response.data) {
                    error = `${e.response.data.error.message}`;
                    code = e.response.data.error.code;
                    data = e.response.data.error.data;
                }
                else if (e.message) {
                    error = e.message;
                }
                else {
                    error = e.response.data.message;
                }
            }
            catch (e2: any) {
                error = `Uncaught Error: ${e2.message}`;
            }
        }
        console.error(code === '' ? 'API Error:' : `${code}:`, error, data ? data : '');
        return error;
    }
    catch (ec) {
        return 'error occurred';
    }
}