import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownIntegerSelectNone, DropdownType } from '../../core/components/form/OEDropdown';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormBoolean from '../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { getParameters, OEParameterType } from '../../core/components/parameters/entities/OEParameter';
import OEParameters from '../../core/components/parameters/OEParameters';
import { Icon } from '../../core/entities/Icon';
import { IMenu, IMenuLookupTables } from '../entities/Menu';
import { useCreateMenu, usePutMenu } from '../services/MenuService';

const labelColumns: number = 2;

interface IFormInfo {
    lookupTables: IMenuLookupTables;
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IMenu> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    return (
        <>
            {touched && touched['name'] && (
                <h2>{touched['name'].valueOf()}</h2>
            )}
            {errors && errors['name'] && (
                <h2>{errors['name']}</h2>
            )}
            <OEFormInput
                label="Name" name="name" value={values.name}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormInput
                label="Icon" name="icon" value={values.icon}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            <OEFormDropdown
                label="Route" name="routeId" value={values.routeId}
                defaultSelect={defaultDropdownIntegerSelectNone} type={DropdownType.Integer} alwaysShowDefault={true}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables.routes}
                setFieldValue={setFieldValue} required={true}
            />

            {(!values.routeId || values.routeId <= 0) && (
                <OEFormInput
                    label="External Url" name="externalUrl" value={values.externalUrl}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue} inputType={FormInputType.String}
                />
            )}

            <OEFormBoolean
                label="Active" name="isActive" value={values.isActive}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue}
            />

            <OEParameters
                label="Parameters" values={getParameters(values.parameters).filter(q => q.type === OEParameterType.URL)}
                setFieldValue={setFieldValue} type={OEParameterType.URL}
            />

        </>
    );
};

const ValidationScheme = Yup.object<IMenu>().shape({
    name: Yup.string().required('Name is required').min(2).max(250).nullable(),
    //    externalUrl: Yup.string()
    //        .when('routeId', {
    //            is: 0,
    //            then: Yup.string().required('External Url is required when no route is chosen.').min(1).nullable()
    //        })
});

interface IFormikInfo {
    item: IMenu;
    parentId: number;
    lookupTables?: IMenuLookupTables;
    onCancel: () => void;
    onSave: () => void;
}

const MenuFormik: React.FunctionComponent<IFormikInfo> = ({ item, parentId, onCancel, onSave, lookupTables }) => {

    const { service, setMenu } = usePutMenu();
    const { service: createService, setMenu: setCreateMenu } = useCreateMenu();
    const [localValues, setLocalValues] = useState<IMenu>(item);

    useEffect(() => {
        setLocalValues(item);
    }, [item]);

    useEffect(() => {
        setLocalValues({ ...localValues, parentId });
        // eslint-disable-next-line
    }, [parentId]);


    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={localValues}
            labelColumns={labelColumns}
            title={`${item.id > 0 ? `Edit Menu: ${item.name}` : `Add New ${parentId > 0 ? 'Child ' : ''}Menu`}`}
            progressMessage={ProgressMessages.Menu}
            successMessage={SuccessMessages.Menu}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            lookupTables={lookupTables}
            component={Form}
            onCancel={onCancel}
            setItem={item.id > 0 ? setMenu : setCreateMenu}
            service={item.id > 0 ? service : createService}
        />
    );
};

export default MenuFormik;
