/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { IMicroStrategyDossierChapter } from '../../entities/MicrostrategyDossierChapter';
import { IMicroStrategyDossierPage } from '../../entities/MicrostrategyDossierPage';
import { setDossierSelectedPage } from '../../services/MicrostrategyDossierService';
declare global { var msLogin: any; }

interface IComponentInfo {
    page: IMicroStrategyDossierPage;
    dossier: any;
    currentIndex: string;
    pageIndex: number;
    chapterIndex: number;
    chapter: IMicroStrategyDossierChapter;
    setCurrentIndex: (i: string) => void;
}

const MicrostrategyPageSection: React.FunctionComponent<IComponentInfo> = ({ chapter, page, setCurrentIndex, currentIndex, dossier, pageIndex, chapterIndex }) => {

    const onSelect = () => {
        setCurrentIndex(`${chapterIndex}-${pageIndex}`);
        setDossierSelectedPage(dossier, chapterIndex, pageIndex);
        dossier.navigateToPage(chapter.getFirstPage());
        let index: number = 0;
        while (index < pageIndex) {
            dossier.goToNextPage();
            index = index + 1;
        }
    }

    return (
        <button onClick={onSelect} id={`reports-tab-group`} role="tab" data-rb-event-key={`group`}
            aria-controls={`reports-tabpane-group`} aria-selected="true"
            className={`nav-item nav-link ${currentIndex === `${chapterIndex}-${pageIndex}` ? 'active' : ''}`}>
            {page.name}
        </button>
    );
};

export default MicrostrategyPageSection;

