import React from 'react';
import OERadioBoolean from '../form/OERadioBoolean';
import OEFormItem, { IOEFormItemInfo } from './OEFormItem';

interface IComponentInfo {
    value: any;
    yesText?: string;
    noText?: string;
}

const OEFormBoolean: React.FunctionComponent<IComponentInfo & IOEFormItemInfo> =
    ({
        columns, name, value, label, errors, touched, setFieldValue, required,
        disabled, hidden, yesText, noText, onChange
    }) => {

        return (
            <OEFormItem columns={columns} name={name} label={label} errors={errors} touched={touched} required={required} hidden={hidden}>
                <>
                    <OERadioBoolean
                        disabled={disabled}
                        yesText={yesText}
                        noText={noText}
                        name={name}
                        value={value}
                        onChange={onChange}
                        setFieldValue={setFieldValue}
                    />
                </>
            </OEFormItem>
        );
    };

export default OEFormBoolean;
