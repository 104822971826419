import { defaultDropdownValues, IDropdownValues } from '../../core/components/form/OEDropdown';
import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';

export enum ReportConfigurationType {
    None = '',
    MinReportHeight = 'DH',
    MaxReportHeight = 'DM',
    MinReportWidth = 'DW',
    MaxReportWidth = 'D2',
    ExportReportFilename = 'EF',
    ExportReport = 'ER',
    ExportPDFilename = 'EPF',
    ExportPDF = 'EPR',
    FiltersMissing = 'FM',
    GroupStyle = 'GS',
    HideApply = 'HA',
    HideFilterSummary = 'HFS',
    ReportHeightPercentage = 'HP',
    Icon = 'IC',
    MultipleReports = 'MR',
    PublicPage = 'PP',
    PageType = 'PT',
    ReportFoooter = 'RF',
    ReportType = 'RT',
    ShowFilters = 'SF',
    ShowNavgiationBar = 'SN',
    ShowTitle = 'ST',
}

export enum ReportConfigurationDataType {
    Boolean = 'BO',
    Dropdown = 'DD',
    Integer = 'IN',
    None = 'NO',
    String = 'ST',
    Html = 'HT'
}

export enum ReportConfigurationGroupStyles {
    Links = 'LK',
    Tabs = 'TB',
}

interface IReportConfigurationType {
    id: string;
    name: string;
    values?: IDropdownValues[];
    dataType: string;
    defaultValue: any;
    disableDelete?: boolean;
}

export interface IReportConfigurationItemForSave extends IBaseAuditedEntity {
    id: string;
    value: any;
}

export interface IReportConfigurationItem extends IReportConfigurationType, IBaseAuditedEntity {
    displayValue: string;
    value: any;
    isSet: boolean;
}

const configurationTypes: IReportConfigurationType[] =
    [
        { id: ReportConfigurationType.MinReportHeight, name: 'Min Report Height', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.MaxReportHeight, name: 'Max Report Height', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.MinReportWidth, name: 'Min Report Width', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.MaxReportWidth, name: 'Max Report Width', dataType: ReportConfigurationDataType.Integer, defaultValue: 0 },
        { id: ReportConfigurationType.ReportHeightPercentage, name: 'Height Percentage', dataType: ReportConfigurationDataType.Integer, defaultValue: 100 },
        {
            id: ReportConfigurationType.PageType, name: 'Reports Type', dataType: ReportConfigurationDataType.Dropdown, defaultValue: 'RE', disableDelete: true,
            values: [
                { ...defaultDropdownValues, id: 'RE', name: 'Embedded Reports' },
                { ...defaultDropdownValues, id: 'RF', name: 'iFrame Reports' },
                { ...defaultDropdownValues, id: 'RS', name: 'Site Reports' },
            ]
        },
        {
            id: ReportConfigurationType.ReportType, name: 'Report Type', dataType: ReportConfigurationDataType.Dropdown, defaultValue: 'RE',
            values: [
                { ...defaultDropdownValues, id: 'RE', name: 'Dossier' },
                { ...defaultDropdownValues, id: 'RP', name: 'Document' },
            ]
        },
        {
            id: ReportConfigurationType.GroupStyle, name: 'Group Style', dataType: ReportConfigurationDataType.Dropdown, defaultValue: ReportConfigurationGroupStyles.Links,
            values: [
                { ...defaultDropdownValues, id: ReportConfigurationGroupStyles.Links, name: 'Links' },
                { ...defaultDropdownValues, id: ReportConfigurationGroupStyles.Tabs, name: 'Tabs' },
            ]
        },
        { id: ReportConfigurationType.PublicPage, name: 'Public', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.FiltersMissing, name: 'Message When Filters Missing', dataType: ReportConfigurationDataType.Html, defaultValue: '' },
        { id: ReportConfigurationType.HideApply, name: 'Hide Apply Button', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.HideFilterSummary, name: 'Hide Dossier Filters Summary', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.MultipleReports, name: 'Allow Multiple Reports', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ShowFilters, name: 'Show Dossier Filters', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ShowNavgiationBar, name: 'Show Dossier Navigation Bar', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.Icon, name: 'Icon', dataType: ReportConfigurationDataType.String, defaultValue: '' },
        { id: ReportConfigurationType.ShowTitle, name: 'Show Report Title', dataType: ReportConfigurationDataType.Boolean, defaultValue: true },
        { id: ReportConfigurationType.ReportFoooter, name: 'Report Footer', dataType: ReportConfigurationDataType.Html, defaultValue: '' },
        { id: ReportConfigurationType.ExportReport, name: 'Export Enabled', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ExportReportFilename, name: 'Export Filename', dataType: ReportConfigurationDataType.String, defaultValue: 'excelExport.xlsx' },
        { id: ReportConfigurationType.ExportPDF, name: 'PDF Enabled', dataType: ReportConfigurationDataType.Boolean, defaultValue: false },
        { id: ReportConfigurationType.ExportPDFilename, name: 'PDF Filename', dataType: ReportConfigurationDataType.String, defaultValue: 'Report.pdf' },
    ];

export const getConfigurationType = (id: string): IReportConfigurationType => {
    return configurationTypes.filter(q => q.id === id)[0];
}

export const defaultReportConfigurationItem: IReportConfigurationItem = {
    id: ReportConfigurationType.None, name: '', dataType: ReportConfigurationDataType.None,
    values: [], value: '', displayValue: '', isSet: false, defaultValue: ''
};

export const getReportConfigurationValues = (id: string): IDropdownValues[] => {
    if (configurationTypes.filter(q => q.id === id).length > 0)
        return configurationTypes.filter(q => q.id === id)[0].values || [];
    return [];
};

export const getReportConfigurationDisplayValue = (i: IReportConfigurationItem): string => {
    switch (i.dataType) {
        case ReportConfigurationDataType.Dropdown:
            const v: IDropdownValues[] = getReportConfigurationValues(i.id);
            if (v.length > 0 && v.filter(q => q.id.toString() === i.value.toString()).length > 0) {
                return v.filter(q => q.id.toString() === i.value.toString())[0].name;
            }
            return '';

        case ReportConfigurationDataType.Boolean:
            return i.value ? 'Yes' : 'No'
                ;
        default:
            return i.value;
    }
};

export const getReportConfigurationDefaultValue = (id: string): any => {
    try {
        return configurationTypes.filter(q => q.id === id)[0].defaultValue;
    }
    catch {
        return '';
    }
};

export const getReportConfigurationValue = (config: string, id: string): any => {
    const v: IReportConfigurationItemForSave[] = getJSONFromReportConfiguration(config);
    if (v && v.length > 0 && v.filter(q => q.id === id).length > 0) {
        const i: IReportConfigurationItemForSave = v.filter(q => q.id === id)[0];

        return i.value;
    }
    return getReportConfigurationDefaultValue(id);
};

export const getJSONForReportConfigurationSave = (i: IReportConfigurationItem[]): string => {
    const j: IReportConfigurationItemForSave[] = [];
    for (const p of i) {
        j.push({ id: p.id, value: p.value });
    }
    return JSON.stringify(j);
}

export const getJSONFromReportConfiguration = (p: string): IReportConfigurationItemForSave[] => {
    try {
        return p === null ? [] : JSON.parse(p);
    }
    catch {
        return [];
    }
}

export const getReportConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideApply) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowNavgiationBar) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowFilters) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportFoooter) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportReport) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportReportFilename) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportPDF) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ExportPDFilename) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportListConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowNavgiationBar) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowFilters) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ReportHeightPercentage) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportPageConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.FiltersMissing) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PageType) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideApply) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.ShowTitle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
    ];
    return [...sortConfigruationArray(d)];
}

export const getReportGroupConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportHeight) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MinReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MaxReportWidth) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.MultipleReports) },
    ];
    return [...sortConfigruationArray(d)];
}
export const getReportSectionConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
    ];
    return [...sortConfigruationArray(d)];
}
export const getReportSectionItemConfigurations = (c: string): IReportConfigurationItem[] => {
    const d: IReportConfigurationItem[] = [
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.GroupStyle) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.HideFilterSummary) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.Icon) },
        { ...defaultReportConfigurationItem, ...getConfigurationType(ReportConfigurationType.PublicPage) },
    ];
    return [...sortConfigruationArray(d)];
}

const sortConfigruationArray = (d: IReportConfigurationItem[]): IReportConfigurationItem[] => {
    return d.sort((n1, n2) => {
        if (n1.name > n2.name) {
            return 1;
        }
        if (n1.name < n2.name) {
            return -1;
        }
        return 0;
    });
};