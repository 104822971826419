import React, { useEffect, useState } from 'react';
import { getReportGroupIcon, IReportGroup } from '../../../reporting/entities/ReportGroup';

interface IComponentInfo {
    group: IReportGroup;
    onClick: (i: IReportGroup) => void;
}

const SectionItem: React.FunctionComponent<IComponentInfo> = ({ group, onClick }) => {

    const [imgSrc, setImgSrc] = useState<string>('');

    useEffect(() => {
        setImgSrc(getReportGroupIcon(group.configuration))
        // eslint-disable-next-line
    }, [group]);

    const onSelect = () => {
        onClick(group);
    }

    return (
        <div onClick={onSelect} >
            {imgSrc !== '' && (
                <img alt="" className="m-r-5" src={imgSrc} ></img>
            )}
            {group.title}
        </div>
    );
};

export default SectionItem;
