import React, { Children, CSSProperties } from 'react';
import OEHeading from './OEHeading';
import OELink from './OELink';

export enum WellStyle {
    General = 'general-well',
    Clear = 'clear-well',
    Bordered = 'bordered-well',
    BorderedShaded = 'bordered-well-shaded',
    BorderedClear = 'clear-well bordered-well',
}

interface IComponentInfo {
    id?: string;
    title?: string;
    icon?: string;
    className?: string;
    oeStyle?: string;
    style?: CSSProperties;
    action?: () => void;
    actionIcon?: string;
    actionText?: string;
    headingSize?: number;
    children?: any;
}

const OEWell: React.FunctionComponent<IComponentInfo> = ({
    className, title, icon, id, style, children, oeStyle, action, actionIcon,
    actionText, headingSize
}) => {

    return (
        <>
            <div
                className={` container well ${className || ''} ${oeStyle || WellStyle.General}`}
                id={id}
                style={{ ...style, maxWidth: '100%' }}
            >
                {action && actionIcon && (
                    <OELink text={actionText} className="m-r-10 pull-right" icon={actionIcon} onClick={action} />
                )}
                {title && (
                    <OEHeading className="m-t-0 m-l=0 m-b-10" icon={icon} text={title} size={headingSize || 4} />
                )}
                {Children.map(children, i => i)}
            </div>
            <div className="cleardiv" />
        </>
    );

};

export default OEWell;