import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';

export enum ReportURLExtension {
    None = '',
    ProjectID = 'PROJ',
    DocumentID = 'DOC',
    PageID = 'PAGE',
    LevelID = 'LEVEL'
}

interface IReportURLExtensionType {
    id: string;
    name: string;
    report: boolean;
    sortOrder: number;
}

export interface IReportURLExtensionForSave extends IBaseAuditedEntity {
    id: string;
    value: any;
}

export interface IReportURLExtension extends IReportURLExtensionType, IBaseAuditedEntity {
    value: any;
    isSet: boolean;
}

const urlExtenssions: IReportURLExtensionType[] =
    [
        { id: ReportURLExtension.ProjectID, name: 'Project ID', report: true, sortOrder: 1 },
        { id: ReportURLExtension.DocumentID, name: 'Document ID', report: true, sortOrder: 2 },
        { id: ReportURLExtension.PageID, name: 'Page ID', report: true, sortOrder: 3 },
        { id: ReportURLExtension.LevelID, name: 'Level ID', report: false, sortOrder: 4 },

    ];

const getURLExtension = (id: string): IReportURLExtensionType => {
    return urlExtenssions.filter(q => q.id === id)[0];
}

export const defaultReportURLExtension: IReportURLExtension = {
    id: ReportURLExtension.None, name: '', report: false, sortOrder: -1, value: '', isSet: false
};

export const getConfigurationDisplayValue = (i: IReportURLExtension): string => {
    switch (i.dataType) {
        default:
            return i.value;
    }
};

export const getConfigurationValue = (config: string, id: string): any => {
    const v: IReportURLExtensionForSave[] = getJSONFromReportConfiguration(config);
    if (v && v.length > 0 && v.filter(q => q.id === id).length > 0) {
        const i: IReportURLExtensionForSave = v.filter(q => q.id === id)[0];

        return i.value;
    }
    return '';
};

export const getJSONForReportConfigurationSave = (i: IReportURLExtension[]): string => {
    const j: IReportURLExtensionForSave[] = [];
    for (const p of i) {
        j.push({ id: p.id, value: p.value });
    }
    return JSON.stringify(j);
}

export const getJSONFromReportConfiguration = (p: string): IReportURLExtensionForSave[] => {
    try {
        return p === null ? [] : JSON.parse(p);
    }
    catch {
        return [];
    }
}

export const getReportURLExtensions = (c: string): IReportURLExtension[] => {
    const d: IReportURLExtension[] = [
        { ...defaultReportURLExtension, ...getURLExtension(ReportURLExtension.PageID) },
        { ...defaultReportURLExtension, ...getURLExtension(ReportURLExtension.DocumentID) },
        { ...defaultReportURLExtension, ...getURLExtension(ReportURLExtension.ProjectID) },
        { ...defaultReportURLExtension, ...getURLExtension(ReportURLExtension.LevelID) },
    ];
    return [...d];
}

export const getReportListConfigurations = (c: string): IReportURLExtension[] => {
    const d: IReportURLExtension[] = [
        { ...defaultReportURLExtension, ...getURLExtension(ReportURLExtension.LevelID) },
    ];
    return [...d];
}
