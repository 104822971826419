import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultProfile, IProfile } from '../entities/Profile';

export const useGetProfile = () => {
    const [service, setService] = useState(GetRequest.empty<IProfile>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/account/my-profile`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const usePutProfile = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [profile, setProfile] = useState<IProfile>(defaultProfile);

    useEffect(() => {
        if (profile.userName === '') { return; }
        processPutAsync(setService, `/api/account/my-profile/`, profile);
        setProfile(profile);
    }, [profile]);

    return { service, setProfile };
};
