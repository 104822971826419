export const defaultAlertId: string = "00000000-0000-0000-0000-000000000000";

export interface IAlertSubscription {
    id: string;
    eventName?: string;
    isSubscribed: boolean
}

export const defaultAlertSubscription: IAlertSubscription = {
    id: defaultAlertId,
    eventName: "",
    isSubscribed: false,
};

export const defaultAlertSubscriptions: IAlertSubscription[] = [];