import React, { useEffect, useState } from 'react';
import { INotification } from '../../core/components/messaging/entities/Notification';
import OESpinner from '../../core/components/messaging/OESpinner';
import PageContent from '../components/PageContent';
import WidgetContent from '../components/WidgetContent';
import WidgetFormik from '../components/WidgetFormik';
import { getWidgetList, IWidget } from '../entities/Widget';
import { getWidgetGroupList } from '../entities/WidgetGroup';
import { useGetWidgetGroupByCode } from '../services/WidgetGroupService';
import { useGetWidgetsByGroupId } from '../services/WidgetService';

enum ModalTypes {
    None = 1,
    Edit = 2,
}

interface IComponentInfo {
    setNotification: (i: INotification) => void;
}

const HomePageAdmin: React.FunctionComponent<IComponentInfo> = ({ setNotification }) => {
    const groupCode: string = 'HOMEPAGE';
    const { service: groupService } = useGetWidgetGroupByCode(groupCode);
    const { service, setGroupId: setServiceGroupId } = useGetWidgetsByGroupId();

    const [groupId, setGroupId] = useState<number>(-1);
    const [widgetId, setWidgetId] = useState<string>('');
    const [items, setItems] = useState<IWidget[]>([]);
    const [modalType, setModalType] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (groupService.result) {
            setGroupId(getWidgetGroupList(groupService.result)[0].id)
        }
    }, [groupService]);

    useEffect(() => {
        if (service.result) {
            setItems(getWidgetList(service.result));
        }
    }, [service]);

    useEffect(() => {
        setServiceGroupId(groupId);
        // eslint-disable-next-line
    }, [groupId]);

    const onEdit = (i: string) => {
        setWidgetId(i);
        setModalType(ModalTypes.Edit);
    };

    const onSave = () => {
        setModalType(ModalTypes.None);
        setServiceGroupId(groupId);
        setWidgetId('');
    };

    const onCancel = () => {
        setModalType(ModalTypes.None);
        setWidgetId('');
    };

    return (
        <div className="container">
            <OESpinner message="Loading Content" hidden={!service.isInProgress} />

            {modalType === ModalTypes.Edit && (
                <WidgetFormik onCancel={onCancel} onSave={onSave} widgetId={widgetId} />
            )}

            {modalType === ModalTypes.None && items.length > 0 && (
                <>
                    <PageContent item={items.filter(q => q.code === 'CONTENT')[0]} action={onEdit}>
                        <WidgetContent items={items} code='CONTENT' />
                    </PageContent>

                    <div className="row">
                        <div className="col-8 m-t-40">
                            <PageContent item={items.filter(q => q.code === 'FOOTER1')[0]} action={onEdit}>
                                <WidgetContent items={items} code='FOOTER1' />
                            </PageContent>
                        </div>
                        <div className="col-md-4 m-t-40">
                            <PageContent item={items.filter(q => q.code === 'FOOTER2')[0]} action={onEdit}>
                                <WidgetContent items={items} code='FOOTER2' />
                            </PageContent>
                        </div>
                    </div>
                </>
            )}

        </div>
    );


};

export default HomePageAdmin;