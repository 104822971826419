import { FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { EditorHeight } from '../../../core/components/form/OEEditor';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormEditor from '../../../core/components/formik/OEFormEditor';
import OEFormikModal from '../../../core/components/formik/OEFormikModal';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../../core/components/messaging/enums/SuccessMessages';
import { Icon } from '../../../core/entities/Icon';
import { getReportGroupConfigurations } from '../../entities/ReportConfiguration';
import { IReportGroup, ReportGroupType } from '../../entities/ReportGroup';
import { useCreateReportGroup, usePutReportGroup } from '../../services/ReportGroupService';
import Configuration from '../report-configruation/Configuration';
import Filters from '../report-configruation/Filters';

const labelColumns: number = 2;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IReportGroup> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            <OEFormDropdown
                label="Group Type" name="groupType" value={values.groupType} autoFocus={true}
                errors={errors} touched={touched} columns={labelColumns} setFieldValue={setFieldValue}
                values={[
                    { id: ReportGroupType.Report, name: 'Reports' },
                    { id: ReportGroupType.AboutPage, name: 'About Page' },
                ]}
            />

            <OEFormInput
                label="Title" name="title" value={values.title}
                errors={errors} touched={touched} columns={labelColumns}
                required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
            />

            {values.groupType !== ReportGroupType.AboutPage && (
                <>
                    <OEFormInput
                        label="Description" name="description" value={values.description}
                        errors={errors} touched={touched} columns={labelColumns}
                        setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.LargeText} rows={5}
                    />

                    <Filters isReport={false} values={values} filters={[]} setFieldValue={setFieldValue} />
                </>
            )}

            <Configuration
                values={values}
                configurations={getReportGroupConfigurations(values.configuration)}
                setFieldValue={setFieldValue}
            />

            {values.groupType === ReportGroupType.AboutPage && (
                <OEFormEditor
                    label="About" name="about" value={values.about}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue} height={EditorHeight.Small}
                />
            )}

            <OEFormBoolean
                label="Active" name="isActive" value={values.isActive}
                errors={errors} touched={touched} columns={labelColumns}
                setFieldValue={setFieldValue}
            />

        </>
    );
};

const ValidationScheme = Yup.object<IReportGroup>().shape({
    title: Yup.string().required('Title is required').min(2).max(250).nullable(),
});

interface IFormikInfo {
    item: IReportGroup;
    onCancel: () => void;
    onSuccess: () => void;
}

const ReportGroupFormik: React.FunctionComponent<IFormikInfo> = ({ item, onCancel, onSuccess }) => {

    const { service, setItem } = usePutReportGroup();
    const { service: createService, setItem: setCreateReportGroup } = useCreateReportGroup();

    return (
        <OEFormikModal
            icon={Icon.Edit}
            item={item}
            labelColumns={labelColumns}
            title={`${item.id === '' ? `Add New Report Group` : `Edit Report Group: ${item.title}`}`}
            progressMessage={ProgressMessages.ReportGroup}
            successMessage={SuccessMessages.ReportGroup}
            onSave={onSuccess}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={item.id === '' ? setCreateReportGroup : setItem}
            service={item.id === '' ? createService : service}
        />
    );
};

export default ReportGroupFormik;
