import React, { useEffect, useState } from 'react';
import { defaultConfirmationMessage, IConfirmationMessage } from '../../../core/components/messaging/entities/ConfirmationMessage';
import OEConfirmation from '../../../core/components/messaging/OEConfirmation';
import OEErrorList from '../../../core/components/messaging/OEErrorList';
import { ISortData } from '../../../core/components/sort/entities/SortData';
import OESort from '../../../core/components/sort/OESort';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import OETable from '../../../core/components/table/OETable';
import { Icon } from '../../../core/entities/Icon';
import { getReportConfigurationValue, ReportConfigurationType } from '../../entities/ReportConfiguration';
import { defaultReportGroup, getReportGroupList, getReportSectionName, IReportGroup, ReportSectionType } from '../../entities/ReportGroup';
import { useDeleteReportGroup, useGetReportGroupsByGroup, useGetReportGroupsByPage, usePutReportGroup } from '../../services/ReportGroupService';
import ReportSectionFormik from './ReportSectionFormik';

enum ModalTypes {
    None = 1,
    Sort,
    Edit,
}

interface IGroupList {
    sectionType: ReportSectionType;
    parentId: string;
    setSection: (i: IReportGroup) => void;
}

const ReportSections: React.FunctionComponent<IGroupList> = ({ setSection, parentId, sectionType }) => {

    const { service: pageService, setItemId: setPageId } = useGetReportGroupsByPage();
    const { service: groupService, setItemId: setGroupId } = useGetReportGroupsByGroup();
    const { service: deleteService, setGroupId: setDeleteId } = useDeleteReportGroup();
    const { service: putService, setItem: setPutReportGroup } = usePutReportGroup();
    const { service: activeService, setItem: setActiveServiceItem } = usePutReportGroup();

    const [items, setItems] = useState<IReportGroup[]>([]);
    const [item, setItem] = useState<IReportGroup>(defaultReportGroup);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [confirmation, setConfirmation] = useState<IConfirmationMessage>(defaultConfirmationMessage);
    const [sortList, setSortList] = useState<ISortData[]>([]);
    const [saveList, setSaveList] = useState<IReportGroup[]>([]);
    const [errors, setErrors] = useState<string[]>([]);
    const [type, setType] = useState<string>('');

    useEffect(() => {
        if (pageService.result) {
            setShowModal(ModalTypes.None);
            setItems(getReportGroupList(pageService.result));
        }
    }, [pageService]);

    useEffect(() => {
        if (groupService.result) {
            setShowModal(ModalTypes.None);
            const c: IReportGroup[] = getReportGroupList(groupService.result);
            for (const i of c) {
                if (getReportConfigurationValue(i.configuration, ReportConfigurationType.PublicPage)) {
                    i.isPublic = true;
                }
            }
            setItems(c);
        }
    }, [groupService]);

    useEffect(() => {
        refreshItems();
        // eslint-disable-next-line
    }, [parentId]);

    useEffect(() => {
        setType(getReportSectionName(sectionType));
        // eslint-disable-next-line
    }, [sectionType]);

    useEffect(() => {
        if (deleteService.isSuccess) {
            refreshItems();
        }
        // eslint-disable-next-line
    }, [deleteService]);

    useEffect(() => {
        if (putService.isFinished) {
            if (putService.isSuccess) {
                saveList.shift();
                setSaveList([...saveList]);
                if (saveList.length === 0) {
                    refreshItems();
                }
            } else {
                setErrors([putService.response.message] || ['An error occurred generating request']);
            }
        }
        // eslint-disable-next-line
    }, [putService]);

    useEffect(() => {
        if (activeService.isFinished && !activeService.isSuccess) {
            setErrors([activeService.response.message] || ['An error occurred updating the active status']);
        }
    }, [activeService]);

    useEffect(() => {
        if (saveList.length > 0) {
            setPutReportGroup(saveList[0]);
        } else {
            setShowModal(ModalTypes.None);
        }
        // eslint-disable-next-line
    }, [saveList]);

    const refreshItems = () => {
        sectionType === ReportSectionType.SectionGroup ? setPageId(parentId) : setGroupId(parentId);
    }

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onAdd = () => {
        if (sectionType === ReportSectionType.SectionGroup) {
            setItem({
                ...defaultReportGroup, reportPageId: parentId, sortOrder: items.length + 1
            });
        }
        else {
            setItem({
                ...defaultReportGroup, reportGroupId: parentId, sortOrder: items.length + 1
            });
        }
        setShowModal(ModalTypes.Edit);
    };

    const onEdit = (i: IReportGroup) => {
        setItem({ ...i, sectionType } );
        setShowModal(ModalTypes.Edit);
    };

    const onSuccess = () => {
        refreshItems();
    };

    const onDelete = (i: string) => {
        setDeleteId(i);
    };

    const onSort = () => {
        setErrors([]);
        if (items) {
            const list: ISortData[] = [];
            for (const item of items) {
                list.push({ id: item.id, name: item.title, sortOrder: item.sortOrder });
            }
            setSortList(list);
            setShowModal(ModalTypes.Sort);
        }
    };

    const onSortOK = (d: ISortData[]) => {
        const l: IReportGroup[] = [];
        for (const m of d) {
            const m2 = items.filter(q => q.id === m.id)[0];
            if (m2.sortOrder !== m.sortOrder) {
                l.push({ ...m2, sortOrder: m.sortOrder });
            }
        }
        setSaveList(l);
        if (l.length === 0) {
            setShowModal(ModalTypes.None);
        }
    };

    const onUpdateActive = (i: IReportGroup) => {
        i.isActive = !i.isActive;
        setActiveServiceItem({ ...i });
    };

    const onConfirmDelete = (i: IReportGroup) => {
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i.id, show: true, title: "Delete Section?",
            message: `Are you sure you want to delete the section <b>${i.name}</b>?`,
            onOk: onDelete, onCancel: onCancel
        });
    };

    const onConfirmActive = (i: IReportGroup) => {
        setErrors([]);
        setConfirmation({
            ...defaultConfirmationMessage, setConfirmation, item: i, show: true, title: "Update Active Status?",
            message: `Are you sure you want change the active status for the section <b>${i.title}</b>?`,
            onOk: onUpdateActive, onCancel: onCancel
        });
    };

    const onViewChildren = (i: IReportGroup) => {
        setSection(i);
    };


    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEdit, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onConfirmDelete, condition: "childCount", notCondition: true, helpText: 'Delete' },
        { icon: Icon.CheckYes, condition: 'isActive', onClick: onConfirmActive, helpText: `Deactivate` },
        { icon: Icon.CheckNo, condition: 'isActive', notCondition: true, onClick: onConfirmActive, helpText: `Activate` },
    ];

    const childactions: IColumnAction[] = [
        { icon: Icon.ChildrenNavigate, onClick: onViewChildren, helpText: 'View Children' }
    ];

    const columns: IColumn[] = [
        { actions: childactions, width: '10px', id: '', name: '', sort: false, type: ColumnType.Actions },
        { id: 'iconPath', name: 'Icon', width: '15px', sort: true, type: ColumnType.Image, className: 'text-center' },
        { id: 'title', name: 'Title', sort: true, type: ColumnType.Link, onClick: onEdit, helpText: 'Edit Section' },
        { id: 'description', name: 'Description', sort: true, type: ColumnType.String, displayHTML: true },
        { id: 'sortOrder', width: "20px", name: 'Order', sort: true, type: ColumnType.Integer, className: 'text-center' },
        { id: 'reportCount', name: 'Children', sort: true, type: ColumnType.Integer, className: 'text-center' },
        { id: 'groupCount', name: 'Children', sort: true, type: ColumnType.Integer, className: 'text-center' },
    ];

    if (sectionType !== ReportSectionType.SectionHeader) {
        columns.push({ id: 'isPublic', name: 'Public', sort: true, type: ColumnType.Check, className: 'text-center' });
    }
    columns.push({ actions, id: 'Opitions', width: '20px', name: '', sort: false, type: ColumnType.Actions });

    return (
        <>
            <OEConfirmation {...confirmation} />
            <OEErrorList errors={errors} />

            {showModal !== ModalTypes.Edit && (
                <OETable
                    loading={pageService.isInProgress || groupService.isInProgress}
                    loadingMessage="Loading Sections"
                    data={items}
                    noDataMessage="There are no sections"
                    columns={columns}
                    showPagination={true}
                    defaultSort="name"
                    defaultPageSize={10}
                    actions={[
                        { icon: Icon.Add, text: `Add New ${type}`, action: onAdd },
                        { hidden: items.length < 2, icon: Icon.Sort, text: `Sort ${type}s`, action: onSort },
                    ]}
                />
            )}
            {showModal === ModalTypes.Edit && (
                <ReportSectionFormik type={type} onCancel={onCancel} onSuccess={onSuccess} item={item} />
            )}
            {showModal === ModalTypes.Sort && (
                <OESort show errors={errors} items={sortList} title={`Sort ${type}`} onOk={onSortOK} onCancel={onCancel} />
            )}

        </>
    );
};


export default ReportSections;