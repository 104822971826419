import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { processPutAsync, PutRequest } from '../../core/services/PutRequest';
import { defaultWidget, IWidget, IWidgetListItem } from '../entities/Widget';

export const useGetWidgets = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetListItem>>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/widgets?MaxResultCount=1000`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetWidget = () => {
    const [service, setService] = useState(GetRequest.empty<IWidgetListItem>());
    const [widgetId, setWidgetId] = useState<string>('');

    useEffect(() => {
        if (widgetId === '') { return; }
        processGetAsync(setService, `/api/app/widgets/${widgetId}/with-navigation-properties`);
        setWidgetId('')
    }, [widgetId]);

    return { service, setWidgetId };
};

export const usePutWidget = () => {
    const [service, setService] = useState(PutRequest.empty());
    const [item, setItem] = useState<IWidget>(defaultWidget);

    useEffect(() => {
        if (item.id === '') { return; }
        processPutAsync(setService, `/api/app/widgets/${item.id}`, item);
        setItem(defaultWidget);
    }, [item]);

    return { service, setItem };
};

export const useGetWidgetsByGroupId = () => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IWidgetListItem>>());
    const [groupId, setGroupId] = useState<number>(-1);

    useEffect(() => {
        if (groupId <0) { return; }
        processGetAsync(setService, `api/app/widgets?WidgetGroupId=${groupId}`);
        setGroupId(-1)
    }, [groupId]);

    return { service, setGroupId };
};
