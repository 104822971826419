import React, { useState } from 'react';
import OEHeading from '../core/components/general/OEHeading';
import { defaultNotification, INotification } from '../core/components/messaging/entities/Notification';
import OENotification from '../core/components/messaging/OENotification';
import OETabs, { OETab } from '../core/components/tabs/OETabs';
import { Icon } from '../core/entities/Icon';
import HomePageAdmin from './components/HomePageAdmin';
import PublicHomePageAdmin from './components/PublicHomePageAdmin';


const WidgetAdmin: React.FunctionComponent = () => {
    const [notification, setNotification] = useState<INotification>(defaultNotification);
    const [activeTab, setActiveTab] = useState('home');

    const setTab = (event: any) => {
        setActiveTab(event);
    };
    return (
        <div className="container">
            <OENotification setNotification={setNotification} notification={notification} />
            <OEHeading className="m-b-20 m-t-10" text="Content Administration" icon={Icon.ContentAdministration} />
            <OETabs className="fst-italic small" id="orgtypetabs" defaultTab={activeTab} onSelect={setTab}>
                <OETab title={`Home Page`} eventKey="home">
                    <HomePageAdmin setNotification={setNotification} />
                </OETab>
                <OETab title={`Public Home Page`} eventKey="public">
                    <PublicHomePageAdmin setNotification={setNotification} />
                </OETab>
            </OETabs>
        </div>
    );


};

export default WidgetAdmin;