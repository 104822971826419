import { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownIntegerSelect, defaultDropdownSelect } from '../../core/components/form/OEDropdown';
import { IFormInfo } from '../../core/components/formik/entities/Form';
import OEFormDropdown from '../../core/components/formik/OEFormDropdown';
import OEFormikModal from '../../core/components/formik/OEFormikModal';
import { ModalSize } from '../../core/components/general/OEModal';
import { IArtifactDatasetViewColumns } from '../entities/ArtifactDatasetViewColumns';
import { IDatasetFilter } from '../entities/DatasetFilter';
import { DatasetFilterSelectorSelect } from '../entities/DatasetFilterSelector';

const labelColumns: number = 3;

const Form: React.FunctionComponent<FormikProps<IDatasetFilter> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {
    return (
        <>
            <OEFormDropdown label="Name" name="filterName" value={values.filterName}
                defaultSelect={ defaultDropdownSelect }
                alwaysShowDefault={true} errors={errors} touched={touched} columns={labelColumns}
                values={lookupTables} setFieldValue={setFieldValue} required={true}
                fields={{ id: 'id', name: 'displayName' }}
            />


            < OEFormDropdown label="Filter Selector" name="filterSelector" value={values.filterSelector}
                defaultSelect={defaultDropdownIntegerSelect} alwaysShowDefault={true} errors={errors} touched={touched} columns={labelColumns}
                values={DatasetFilterSelectorSelect} setFieldValue={setFieldValue} required={true} />

        </>
    );
};

const ValidationScheme = Yup.object<IDatasetFilter>().shape({
    filterName: Yup.string().required('Name is required').nullable(),
    filterSelector: Yup.number().min(1,'Filter Selector is required').nullable(),
});

interface IFormikInfo {
    item: IDatasetFilter;
    existing: IDatasetFilter[];
    columnData: IArtifactDatasetViewColumns[];
    onCancel: () => void;
    onSave: (item: IDatasetFilter) => void;
}

const FilterFormik: React.FunctionComponent<IFormikInfo> = ({
    item, onCancel, onSave, existing, columnData
}) => {

    const [lookupTables, setLookupTables] = useState<IArtifactDatasetViewColumns[]>([]);

    useEffect(() => {
        const i: IArtifactDatasetViewColumns[] = [];
        for (const c of columnData) {
            if (c.displayName === item.filterName || existing.filter(q => q.filterName === c.displayName).length ===0) {
                i.push(c);
            }
        }
        setLookupTables(i);
        // eslint-disable-next-line
    }, [columnData, existing]);


    return (
        <OEFormikModal
            oeSize={ModalSize.Medium}
            item={item}
            labelColumns={12}
            title={`Add/Edit Fitler`}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            lookupTables={lookupTables}
            onCancel={onCancel}
            setItem={onSave}
        />
    );
};

export default FilterFormik;