import { Icon } from "../../core/entities/Icon";
import { getConfigurationType, ReportConfigurationType } from "../../reporting/entities/ReportConfiguration";
import { getConfigurationValue } from "../../reporting/entities/ReportURLExtensions";

export enum MicroStrategyExportType {
    PDF = 'pdf',
    Excel = 'excel',
}

export interface IMicroStrategyExportOptions {
    type: MicroStrategyExportType;
    export: boolean;
    filename: string;
    text: string;
    icon: string;
}


export const getExportOptions = (c: string): IMicroStrategyExportOptions[] => {
    const a: IMicroStrategyExportOptions[] = [];
    if (getConfigurationValue(c, ReportConfigurationType.ExportReport)) {
        a.push({
            type: MicroStrategyExportType.Excel,
            icon: Icon.Excel,
            export: false,
            filename: getConfigurationValue(c, ReportConfigurationType.ExportReportFilename) ||
                getConfigurationType(ReportConfigurationType.ExportReportFilename).defaultValue,
            text: 'Export to Excel'
        });
    }
    if (getConfigurationValue(c, ReportConfigurationType.ExportPDF)) {
        a.push({
            type: MicroStrategyExportType.PDF,
            icon: Icon.PDF,
            export: false,
            filename: getConfigurationValue(c, ReportConfigurationType.ExportPDFilename) ||
                getConfigurationType(ReportConfigurationType.ExportPDFilename).defaultValue,
            text: 'Export to PDF'
        });
    }
    return a;
}