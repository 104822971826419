export interface IDossier {
    getFilterList: () => void;
}

export interface IDossierFilterDetailItems {
    name: string;
    value: string;
    selected: boolean;
}

export interface IDossierFilterDetail {
    supportMultiple: boolean;
    items: IDossierFilterDetailItems[];
}

export const defaultDossierFilterDetail: IDossierFilterDetail = {
    items: [], supportMultiple: false
};

export interface IDossierFilter {
    filterName: string;
    filterKey: string;
    filterType: string;
    filterDetail: IDossierFilterDetail;
    isExclude: boolean;
}

export enum DossierFilterType {
    MultiSelect = 1,
    Single,
    Range,
}
export interface IDossierFilterSelection {
    key: string;
    type: DossierFilterType;
    selectionData: any;
    deselect: boolean;
}

export const defaultDossierFilter: IDossierFilter = {
    filterDetail: defaultDossierFilterDetail, filterKey: '', filterName: '', filterType: '', isExclude: false
};


interface IFeature { readonly: boolean; }
const defaultFeature: IFeature = { readonly: false };

interface IOptionsFeature { enabled: boolean; help: boolean; logout: boolean; manage: boolean; showTutorials: boolean; }
const defaultOptionsFeature: IOptionsFeature = { enabled: true, help: false, logout: true, manage: false, showTutorials: false, };

interface IShareFeature {
    enabled: boolean; invite: boolean; link: boolean; email: boolean; export: boolean;
    download: boolean; shareDossier: boolean; subscribe: boolean;
}
const defaultShareFeature: IShareFeature = {
    enabled: true, invite: false, link: true, email: false, export: true,
    download: false, shareDossier: false, subscribe: false
};

interface INavigationBar {
    enabled: boolean; gotoLibrary: boolean; title: boolean; toc: boolean; reset: boolean; reprompt: boolean; share: boolean;
    comment: boolean; notification: boolean; filter: boolean; options: boolean; search: boolean; bookmark: boolean; edit: boolean;
}
export const defaultNavigationBar: INavigationBar = {
    enabled: false, gotoLibrary: true, title: true, toc: true, reset: true, reprompt: true, share: true,
    comment: false, notification: true, filter: true, options: true, search: true, bookmark: true, edit: false,
};

interface IFilterFeature { enabled: boolean; edit: boolean; summary: boolean; }
const defaultFilterFeature: IFilterFeature = { enabled: false, edit: false, summary: false };

interface IComment { dockedPosition: string; canClose: boolean; dockChangeable: boolean; isDocked: boolean; theme: string; }
const defaultComment: IComment = { dockedPosition: "left", canClose: true, dockChangeable: false, isDocked: false, theme: 'light' };

interface ITocFeature { enabled: boolean; }
const defaultTocFeature: ITocFeature = { enabled: false };

interface IUIMessage { enabled: boolean; addToLibrary: boolean; }
const defaultUIMessage: IUIMessage = { enabled: true, addToLibrary: false, };

interface IVisibleTutorials { library: boolean; welcome: boolean; dossier: boolean; notification: boolean; }
const defaultVisibleTutorials: IVisibleTutorials = { library: false, welcome: false, dossier: true, notification: false, };

export interface IDossierConfiguration {
    url: string;
    placeholder: any;
    containerHeight: string;
    containerWidth: string;
    customAuthenticationType: any;
    disableNotification: boolean;
    dockedComment: IComment;
    dockedFilter: IComment;
    dockedTOC: IComment;
    dossierFeature: IFeature
    enableCollaboration: boolean;
    enableCustomAuthentication: boolean;
    enableResponsive: boolean;
    filterFeature: IFilterFeature;
    filters: any;
    navigationBar: INavigationBar;
    optionsFeature: IOptionsFeature;
    shareFeature: IShareFeature;
    smartBanner: boolean;
    tocFeature: ITocFeature;
    uiMessage: IUIMessage;
    visibleTutorials: IVisibleTutorials;
    getLoginToken: any;
    disableCustomErrorHandlerOnCreate?: boolean;
    errorHandler?: (e: any) => void;
    sessionErrorHandler?: (e: any) => void;
}

export const defaultDossierConfiguration: IDossierConfiguration = {
    url: "", placeholder: null, containerHeight: "600px", containerWidth: "800px", customAuthenticationType: null,
    disableNotification: true, dockedComment: defaultComment, dockedFilter: defaultComment, dockedTOC: defaultComment,
    dossierFeature: defaultFeature, enableCollaboration: true, enableCustomAuthentication: false, enableResponsive: true,
    filterFeature: defaultFilterFeature, navigationBar: defaultNavigationBar, optionsFeature: defaultOptionsFeature,
    shareFeature: defaultShareFeature, smartBanner: false, tocFeature: defaultTocFeature, uiMessage: defaultUIMessage,
    visibleTutorials: defaultVisibleTutorials, filters: [], getLoginToken: null
};

export interface IDossierSettings {
    hideFilterSummary?: boolean
    showFilters: boolean;
    showNavigationBar: boolean;
}

export const defaultDossierSettings: IDossierSettings = {
    hideFilterSummary: false, showFilters: false, showNavigationBar: false
};

export interface IDossierCreation {
    divId: string;
    url: string;
    config: IDossierConfiguration;
    debug?: boolean;
    filtersUpdated?: (e: any) => void;
    pageRendered?: (e: any) => void;
    pageSwitched?: (e: any) => void;
    dossierChanged?: (e: any) => void;
    onError?: (s: any) => void;
    settings: IDossierSettings;
    errorHandler?: (e: any) => void;
    disableCustomErrorHandlerOnCreate?: boolean;
    baseUrl: string;
}

export const defaultDossierCreation: IDossierCreation = {
    divId: 'report0', url: '', config: defaultDossierConfiguration,
    settings: defaultDossierSettings, disableCustomErrorHandlerOnCreate: false, baseUrl: ''
};

