import axios from 'axios';
import { defaultRequestResponse, IRequestResponse } from '../entities/RequestResponse';
import { getAPIError } from '../utilities/APIError';
import { navigateToPage } from '../utilities/URL';
import { getAuthorizationToken, redirectLogin } from './Authentication';

const config = {
    headers: {
        'RequestVerificationToken': `${getAuthorizationToken()}`
    }
};

export class GetRequest<T> {
    public static error<T>(error: string): GetRequest<T> {
        return new GetRequest<T>(
            false,
            true,
            { ...defaultRequestResponse, message: error },
        );
    }

    public static inProgress<T>(): GetRequest<T> {
        return new GetRequest<T>(
            true,
            false
        );
    }

    public static empty<T>(): GetRequest<T> {
        return new GetRequest<T>(
            false,
            false,
        );
    }

    public static success<T>(result: any): GetRequest<T> {
        return new GetRequest<T>(
            false,
            false,
            { ...defaultRequestResponse, status: result.status },
            result.data
        );
    }

    constructor(
        public isInProgress: boolean,
        public isError: boolean,
        public response?: IRequestResponse,
        public result?: T,
    ) { }
}

export const processGet = async (url: string): Promise<any> => {
    try {
        const result = await axios.get<any>(`${url}`);
        return result.data;
    } catch (e: any) {
        console.log(GetRequest.error<any>(getAPIError(e)));
    }
};

export const processGetAsync = (setService: (p: GetRequest<any>) => void, url: string) => {
    // tslint:disable-next-line:no-floating-promises
    localStorage.setItem('extendsession', '1');
    getAsync(setService, url);
};

const getAsync = async (setService: (p: GetRequest<any>) => void, url: string) => {
    setService(GetRequest.inProgress<any>());

    try {
        const result = await axios.get<any>(`${url}`);
        if (result.data.toString().includes('<p>You do not have access to this resource.</p>')) {
            localStorage.setItem('error', 'You do not have access to this resource.');
            console.log('Unauthorized Access', url);
        }
        localStorage.setItem('oopscount', '0');
        setService(GetRequest.success<any>(result));
    } catch (e: any) {
        if (!e.message.includes("500")) {
            if (url.includes('my-profile')) {
                navigateToPage('/Account/login');
            }

            localStorage.setItem('error', e.message);
            console.log('500 error', url);
        }
    }
};

export const processGetPostAsync = (setService: (p: GetRequest<any>) => void, url: string, postData?: any) => {
    // tslint:disable-next-line:no-floating-promises
    getPostAsync(setService, url, postData);
};


const getPostAsync = async (setService: (p: GetRequest<any>) => void, url: string, postData?: any) => {
    setService(GetRequest.inProgress<any>());

    try {
        const result = await axios.post<any>(`${url}`, postData, config);
        setService(GetRequest.success<any>(result));
    } catch (e: any) {
        if (url.includes('usermodules')) {
            navigateToPage('/Account/login');
        }
        else {
            setService(GetRequest.error<any>(getAPIError(e)));
        }
    }
};

export const processGetPostMultiPartAsync = (setService: (p: GetRequest<any>) => void, url: string, postData?: any, files?: any, uploadProgress?: (progressEvent: any) => void) => {
    // tslint:disable-next-line:no-floating-promises
    getPostMultiPartAsync(setService, url, postData, files, uploadProgress);
};

const getPostMultiPartAsync = async (setService: (p: GetRequest<any>) => void, url: string, postData?: any, files?: any, uploadProgress?: (progressEvent: any) => void) => {
    try {
        setService(GetRequest.inProgress<any>());

        const formData = new FormData();

        Object.keys(postData).forEach(key => formData.append(key, postData[key]));

        if (files) {
            let index = 0;
            for (const file of files) {
                formData.append(`files[${index}]`, file);
                index = index + 1;
            }
        }

        const result = await axios.post(
            `${url}`
            , formData
            , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: uploadProgress
            }
        );

        setService(GetRequest.success<any>(result));
    } catch (e: any) {
        if (e.response.status === 401) {
            redirectLogin();
        }
        setService(GetRequest.error<any>(getAPIError(e)));
    }
};

