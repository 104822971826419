import { getFutureDate } from "../../core/utilities/Date";

export interface IReportAccessLog {
    reportId: string;
    userId: string;
    accessDate: Date;
    parameters: string;
}

export const defaultReportAccessLog: IReportAccessLog = {
    reportId: '', parameters: '', userId: '00000000-0000-0000-0000-000000000000',  accessDate: getFutureDate(0)
};

