import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from '../../core/entities/BaseList';

export interface IUserExtraProperties {
    TypeId: number;
    DisplayName: string;
}

export const defaultUserExtraProperties: IUserExtraProperties = {
    TypeId: 0,
    DisplayName: ''
};

export interface IUser extends IBaseAuditedEntity {
    id: string;
    userName: string;
    email: string;
    emailConfirmed: boolean;
    name: string;
    surname: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    supportTwoFactor: boolean;
    lockoutEnabled: boolean;
    isLockedOut: boolean;
    extraProperties: IUserExtraProperties;
    childCount: number;
    selected: boolean;
}

export const defaultUser: IUser = {
    id: '', userName: '', email: '', emailConfirmed: false,
    name: '', surname: '', phoneNumber: '', phoneNumberConfirmed: false, supportTwoFactor: false,
    lockoutEnabled: false, isLockedOut: false,   extraProperties: defaultUserExtraProperties,
    childCount: 0, selected: false
};

export const getUserList = (i: IBaseList<IUser>): IUser[] => {
    const d: IUser[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultUser,
            ...v
        });
    }
    for (const v of d) {
        v.childCount = d.filter(q => q.parentId === v.id).length;
    }
    return d;
};

export const getUserForSave = (i: IUser): IUser => {
    return i;
};

