import React, { useEffect, useState } from 'react';
import { IBreadcrumb } from '../../../core/components/breadcrumbs/entities/Breadcrumb';
import OEBreadcrumb from '../../../core/components/breadcrumbs/OEBreadcrumb';
import { updateURLParameter } from '../../../core/utilities/URL';
import { defaultReportGroup, IReportGroup, ReportSectionType } from '../../entities/ReportGroup';
import { defaultReportPage, IReportPage, ReportPageType } from '../../entities/ReportPage';
import ReportGroups from './ReportGroups';
import ReportLists from './ReportLists';
import ReportSections from './ReportSections';
import ReportPages from '../admin-reports/ReportPages';
import { ReportConfigurationType, getReportConfigurationValue } from '../../entities/ReportConfiguration';

enum ViewTypes {
    Pages = 1,
    SectionGroups,
    SectionItems,
    Groups,
    Reports
}

const MSReportPagesThreeTiered: React.FunctionComponent = () => {
    const [page, setPage] = useState<IReportPage>(defaultReportPage);
    const [group, setGroup] = useState<IReportGroup>(defaultReportGroup);
    const [sectionItem, setSectionItem] = useState<IReportGroup>(defaultReportGroup);
    const [sectionGroup, setSectionGroup] = useState<IReportGroup>(defaultReportGroup);
    const rootBreadcrumbs: IBreadcrumb[] = [{ id: 0, name: 'Home' }];
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>(rootBreadcrumbs);
    const [showView, setShowView] = useState<ViewTypes>(ViewTypes.Pages);

    useEffect(() => {
        if (page.id !== '') {
            setBreadcrumbs([...rootBreadcrumbs, { id: 1, name: page.name }]);
            setShowView(ViewTypes.SectionGroups);
            updateURLParameter('pid', page.id);
        }
        else {
            setShowView(ViewTypes.Pages);
            updateURLParameter('pid', '');
        }
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (sectionGroup.id !== '') {
            setShowView(ViewTypes.SectionItems);
            setBreadcrumbs([...breadcrumbs, { id: 2, name: sectionGroup.title }]);
            updateURLParameter('sgid', sectionGroup.id);
        }
        else {
            updateURLParameter('sgid', '');
        }
        // eslint-disable-next-line
    }, [sectionGroup]);

    useEffect(() => {
        if (sectionItem.id !== '') {
            setShowView(ViewTypes.Groups);
            setBreadcrumbs([...breadcrumbs, { id: 3, name: sectionItem.title }]);
            updateURLParameter('siid', group.id);
        }
        else {
            updateURLParameter('siid', '');
        }
        // eslint-disable-next-line
    }, [sectionItem]);

    useEffect(() => {
        if (group.id !== '') {
            setShowView(ViewTypes.Reports);
            setBreadcrumbs([...breadcrumbs, { id: 4, name: group.title }]);
            updateURLParameter('groupid', group.id);
        }
        else {
            updateURLParameter('groupid', '');
        }
        // eslint-disable-next-line
    }, [group]);

    const onNavigateBreadcrumb = (i: IBreadcrumb) => {
        if (i.id === 0) {
            setPage(defaultReportPage);
            setGroup(defaultReportGroup);
        }
        if (i.id === 1) {
            setShowView(ViewTypes.SectionGroups);
            setGroup(defaultReportGroup);
        }
        if (i.id === 2) {
            setShowView(ViewTypes.SectionItems);
            setGroup(defaultReportGroup);
        }
        if (i.id === 3) {
            setShowView(ViewTypes.Groups);
            setGroup(defaultReportGroup);
        }
    };

    return (
        <>
            <OEBreadcrumb breadcrumbs={breadcrumbs} setBreadcrumbs={setBreadcrumbs} navigateBreadcrumb={onNavigateBreadcrumb} />

            {showView === ViewTypes.Pages && (
                <ReportPages defaultConfiguration='[{"id":"PT","value":"RE"}]' pageType={ReportPageType.MSThreeTiered} setPage={setPage} />
            )}

            {showView === ViewTypes.SectionGroups && (
                <ReportSections parentId={page.id} sectionType={ReportSectionType.SectionGroup} setSection={setSectionGroup} />
            )}

            {showView === ViewTypes.SectionItems && (
                <ReportSections parentId={sectionGroup.id} sectionType={ReportSectionType.SectionItem} setSection={setSectionItem} />
            )}

            {showView === ViewTypes.Groups && (
                <ReportGroups parentId={sectionItem.id} pageType={ReportPageType.MSThreeTiered} setGroup={setGroup} />
            )}

            {showView === ViewTypes.Reports && (
                <ReportLists group={group} multipleReports={getReportConfigurationValue(group.configuration,ReportConfigurationType.MultipleReports)} />
            )}

        </>
    );
};


export default MSReportPagesThreeTiered;