import React, { useEffect, useState } from 'react';
import OEDropdown, { IDropdownValues, defaultDropdownValues } from '../../../core/components/form/OEDropdown';
import OEMultiSelect, { defaultMultiSelectLocalization } from '../../../core/components/form/OEMultiSelect';
import { ReportNoFilterTextValue } from '../../entities/ReportFilter';
import { ReportProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { IMSFilter } from '../../entities/MicroStrategyFilters';

interface IComponentInfo {
    processingStep: ReportProcessingStep;
    filter: IMSFilter;
    setValue: (i: IMSFilter) => void;
}

const Filter: React.FunctionComponent<IComponentInfo> = ({ filter, setValue, processingStep }) => {
    const [values, setValues] = useState<IDropdownValues[]>([]);
    const [localValue, setLocalValue] = useState<string>(filter.value);
    const [valueCount, setValueCount] = useState<number>(0);

    useEffect(() => {
        const d: IDropdownValues[] = [];
        if (filter && filter.values) {
            if (filter.dossier && filter.noFilterText) {
                d.push({ ...defaultDropdownValues, id: ReportNoFilterTextValue, name: filter.noFilterText })
            }
            for (const c of filter.values) {
                d.push({ ...defaultDropdownValues, id: c.id, name: c.name })
            }
            if (JSON.stringify(d) !== JSON.stringify(values)) {
                setValues([...d]);

                if (filter.supportMultiple) {
                    const v: string[] = filter.value.split(',');
                    let s: string = '';
                    for (const c of filter.values) {
                        if (filter.value === '' || valueCount !== filter.values.length || v.length === 0 || v.find(q => q.toLowerCase().trim() === c.id.toLowerCase())) {
                            s = `${s}${s.length > 0 ? ',' : ''}${c.id}`;
                        }
                    }
                    updateValue(s);
                    setLocalValue(s);
                }
                else {
                    if (d.length > 0 && d.filter(q => q.id === filter.value).length === 0) {
                        updateValue(d[0].id);
                        setLocalValue(d[0].id);
                    }
                }
            }
        }
        // eslint-disable-next-line
    }, [filter.values]);

    useEffect(() => {
        setValueCount(filter.values.length);
        // eslint-disable-next-line
    }, [filter.values.length]);


    const updateValue = (i: string) => {
        if (i !== filter.value) {
            setValue({ ...filter, value: i });
        }
    }

    return (
        <>
            {filter.values.length > 0 && (
                <div className="filter">
                    {filter.label}
                    <br />
                    {filter.supportMultiple && (
                        <OEMultiSelect
                            value={localValue}
                            className="multi-select"
                            values={filter.values}
                            onChange={updateValue}
                            localization={{
                                ...defaultMultiSelectLocalization,
                                selectAll: filter.selectAllText || defaultMultiSelectLocalization.selectAll
                            }}
                        />
                    )}
                    {!filter.supportMultiple && (
                        <OEDropdown
                            onChange={updateValue}
                            value={filter.value}
                            values={values} />
                    )}
                </div>
            )}
        </>
    );
};

export default Filter;
