import { defaultDropdownValues, IDropdownValues } from "../../core/components/form/OEDropdown";
import { ISiteReport } from "../../reporting/entities/SiteReport";
import { defaultReportBaseFilter, defaultReportFilter, getJSONFromReportFilter, IReportBaseFilter, IReportFilter, ReportFilterType, ReportNoFilterTextValue } from "./ReportFilter";
import { defaultDossierFilter, defaultDossierFilterDetail, IDossierFilter, IDossierFilterDetail } from "./MicroStrategyDossier";

export interface IMSFilter {
    name: string;
    label: string;
    type: ReportFilterType;
    prefix: string;
    value: string;
    displayValue: string;
    key: string;
    supportMultiple: boolean;
    msValue: string;
    externalId: string;
    showSelectAll: boolean;
    selectAllText: string;
    isCascading: boolean;
    values: IDropdownValues[];
    level: number;
    dossier: boolean;
    overideValue: string;
    rangeIncrements?: number;
    ignoreFilter?: boolean;
    noFilterText?: string;
    defaultSelection?: string;
    isActive: boolean;
    filterType: string;
    filterDetail: IDossierFilterDetail;
    sortOrder: number;
}

export const defaultMSFilter: IMSFilter = {
    name: '', label: '', type: ReportFilterType.Dropdown, prefix: '', value: '',
    displayValue: '', key: '', supportMultiple: false, msValue: '', externalId: '',
    showSelectAll: false, isCascading: false, values: [], level: 3, dossier: false, overideValue: '', selectAllText: '',
    isActive: true, filterType: '', filterDetail: defaultDossierFilterDetail, sortOrder: 0
}

export const getMSFilters = (filters: string, siteFilters: IReportFilter[], baseFilters: IReportFilter[], level?: number): IMSFilter[] => {
    const f: IReportBaseFilter[] = getJSONFromReportFilter(filters);
    const i: IMSFilter[] = [];

    for (const c of f) {
        if (siteFilters.filter(q => q.name === c.name).length > 0) {
            const s: IReportFilter = siteFilters.filter(q => q.name === c.name)[0];
            const b: IReportFilter = baseFilters.filter(q => q.name === c.name)[0];
            i.push({
                level: level || 1,
                name: c.name,
                label: c.label,
                key: '',
                type: ReportFilterType.Dropdown,
                value: s.selectedValue,
                displayValue: s.values.filter(q => q.id === s.selectedValue).length > 0 ? s.values.filter(q => q.id === s.selectedValue)[0].name : '',
                msValue: '',
                externalId: b.externalId,
                supportMultiple: false,
                showSelectAll: c.showSelectAll,
                prefix: s.prefix,
                values: s.values,
                isCascading: s.isCascading,
                dossier: s.dossier ? s.dossier : false,
                overideValue: c.overrideValue,
                selectAllText: s.selectAllText,
                rangeIncrements: c.rangeIncrements,
                ignoreFilter: c.ignoreFilter,
                noFilterText: c.noFilterText,
                defaultSelection: c.defaultSelection,
                isActive: true,
                filterType: '',
                filterDetail: defaultDossierFilterDetail,
                sortOrder: c.sortOrder
            });
        }
    }
    return i;
}

export const initMSFilters = (filters: string, baseFilters: IReportFilter[], level?: number): IMSFilter[] => {
    const f: IReportBaseFilter[] = getJSONFromReportFilter(filters);
    const i: IMSFilter[] = [];
    for (const c of f) {
        const b: IReportFilter = baseFilters.filter(q => q.name === c.name).length > 0 ? baseFilters.filter(q => q.name === c.name)[0] : defaultReportFilter;
        i.push({
            level: level || 1,
            name: c.name,
            label: c.label,
            key: '',
            type: ReportFilterType.Dropdown,
            value: b.value,
            displayValue: '',
            msValue: '',
            externalId: b.externalId,
            supportMultiple: false,
            showSelectAll: c.showSelectAll,
            prefix: b.prefix,
            values: [],
            isCascading: b.isCascading,
            dossier: c.dossier,
            overideValue: c.overrideValue,
            selectAllText: b.selectAllText,
            rangeIncrements: c.rangeIncrements,
            ignoreFilter: c.ignoreFilter,
            noFilterText: c.noFilterText,
            defaultSelection: c.defaultSelection,
            isActive: true,
            filterType: '',
            filterDetail: defaultDossierFilterDetail,
            sortOrder: c.sortOrder
        });
    }
    return i;
}

export const updateMSFilters = (filters: IMSFilter[], siteFilters: IReportFilter[]): IMSFilter[] => {
    for (const c of filters) {
        if (siteFilters.filter(q => q.name === c.name).length > 0) {
            const s: IReportFilter = siteFilters.filter(q => q.name === c.name)[0];
            c.displayValue = s.values.filter(q => q.id === s.selectedValue).length > 0 ? s.values.filter(q => q.id === s.selectedValue)[0].name : '';
            c.prefix = s.prefix;
            c.values = s.values;
            c.isCascading = s.isCascading;
            c.selectAllText = s.selectAllText;
        }
    }
    return filters;
}

export const getReportFilters = (filters: IMSFilter[], report: ISiteReport, parentFilters?: IMSFilter[]): IReportFilter[] => {
    const v: IReportFilter[] = [];
    if (parentFilters) {
        for (const c of parentFilters) {

            v.push({ ...report.reportFilters.filter(q => q.name === c.name)[0], value: c.value, name: c.name, showSelectAll: c.showSelectAll, returnValues: false });
            // r.reportFilters.filter(q => q.name === c.name)[0].value = c.id;
        }
    }
    for (const c of filters) {

        v.push({ ...report.reportFilters.filter(q => q.name === c.name)[0], value: c.value, name: c.name, showSelectAll: c.showSelectAll, returnValues: true });
        // r.reportFilters.filter(q => q.name === c.name)[0].value = c.id;
    }
    return v;
}

export const addMSFilters = (filters: IMSFilter[], used: IMSFilter[], dossierFilters: IDossierFilter[]) => {
    for (const c of filters) {
        const msFilter: IDossierFilter = getDossierFilter(dossierFilters, c.externalId);
        if (used.filter(q => q.externalId === c.externalId).length === 0 && msFilter.filterName !== '') {
            console.log(`<===== adding ======>`, msFilter.filterKey, msFilter.filterName);
            used.push({ ...c, key: msFilter.filterKey, supportMultiple: msFilter.filterDetail.supportMultiple });
        }
    }
    console.log(`usedaddMSFilters`, [...used]);
}

const dossierIDMatch = (f: string, id: string) => {
    const ids: string = `,${f.toLowerCase()},`;
    if (ids.includes(`,${id.toLowerCase()},`)) {
        return true;
    }
    return false;
}

export const getDossierMatchingPageFilter = (c: IMSFilter, f: IMSFilter[], r: IReportBaseFilter[]): IMSFilter => {
    for (const i of f) {
        if (!i.dossier && dossierIDMatch(i.externalId, c.name)) {
            return i;
        }

        // if match not find look for match from report
        const i2: IReportBaseFilter = getDossierMatchingReportFilter(c, r);
        if (i.name === i2.name) {
            return i;
        }
    }
    return defaultMSFilter;
}

export const getDossierMatchingReportFilter = (c: IMSFilter, f: IReportBaseFilter[]): IReportBaseFilter => {
    for (const i of f) {
        if (!i.dossier && dossierIDMatch(i.msId || i.externalId, c.name)) {
            return i;
        }
    }
    return defaultReportBaseFilter;
}

export const dossierFilterIsPageFilter = (c: IDossierFilter, f: IMSFilter[]): boolean => {
    for (const i of f) {
        if (!i.dossier && dossierIDMatch(i.externalId, c.filterName)) {
            return true;
        }
    }
    return false;
}

const getDossierFilter = (dossierFilters: IDossierFilter[], filterName: string): IDossierFilter => {
    try {
        for (const c of dossierFilters) {
            if (dossierIDMatch(filterName, c.filterName)) {
                return c;
            }
        }

        for (const c of dossierFilters) {
            if (filterName === c.filterName) {
                return c;
            }
        }
        return defaultDossierFilter;
    }
    catch {
        return defaultDossierFilter;
    }
}

export const getDossierFilterValue = (msFilter: IMSFilter, f: IMSFilter, existing?: IMSFilter[]): string => {
    if (f.value === ReportNoFilterTextValue && f.dossier) {
        return f.value;
    }
    try {

        let prefix: string = '';

        if (existing) {
            if (existing.filter(q => q.name === 'District').length > 0 && f.name === 'School') {
                prefix = `${existing.filter(q => q.name === 'District')[0].value.replace('-', '').replace(/^0+/, '')}-All Schools`;
            }
        }

        let matchValue: string = f.values.filter(q => q.id === f.value)[0].id.toLowerCase() + ';'
        if (msFilter.filterDetail.items.filter(q => q.value.toLowerCase().startsWith(matchValue)).length > 0) {
            return msFilter.filterDetail.items.filter(q => q.value.toLowerCase().startsWith(matchValue))[0].value;
        }

        matchValue = `${f.value.replace(/-/g, '')}-${f.values.filter(q => q.id === f.value)[0].name.toLowerCase()}`.replace(/^0+/, '');
        if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue).length > 0) {
            return msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue)[0].value;
        }

        matchValue = f.values.filter(q => q.id === f.value)[0].name.toLowerCase();
        if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue).length > 0) {
            return msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue)[0].value;
        }

        matchValue = f.values.filter(q => q.id === f.value)[0].id.toLowerCase();
        if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue).length > 0) {
            return msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === matchValue)[0].value;
        }

        if (prefix !== '') {
            if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === prefix.toLowerCase()).length > 0) {
                return msFilter.filterDetail.items.filter(q => q.name.toLowerCase() === prefix.toLowerCase())[0].value;
            }
        }

        matchValue = `all schools`;
        if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase().includes(matchValue)).length > 0) {
            if (f.value === '') {
                return '';
            }
            return msFilter.filterDetail.items.filter(q => q.name.toLowerCase().includes(matchValue))[0].value;
        }

        matchValue = `all districts`;
        if (msFilter.filterDetail.items.filter(q => q.name.toLowerCase().includes(matchValue)).length > 0) {
            return msFilter.filterDetail.items.filter(q => q.name.toLowerCase().includes(matchValue))[0].value;
        }

    }
    catch {
        return '';
    }
    return '';
}

//export const setDossierFilterValue = (f: IMSFilter, dossierFilters: IDossierFilter[], existing: IMSFilter[]): boolean => {
//    let v: boolean = true;
//    const msFilter: IDossierFilter = getDossierFilter(dossierFilters, f.externalId);
//    if (msFilter.filterName !== '') {
//        const value: string = getDossierFilterValue(msFilter, f, existing);
//        if (f.value !== '' && value === '') {
//            v = false;
//            f.msValue = ''
//        }
//        else {
//            f.msValue = value;
//        }
//    }
//    return v;
//}

//export const getDossierFilterSelections = (used: IMSFilter[], dossier: any, dossierFilters: IDossierFilter[]): IDossierFilterSelection[] => {
//    const s: IDossierFilterSelection[] = [];
//    if (dossier && dossierFilters) {
//        for (const f of used) {
//            const selections: IMicroStrategyDossierFilterSelection[] = [];
//            const msFilter: IDossierFilter = getDossierFilter(dossierFilters, f.externalId);
//            const i: IDossierFilterSelection = {
//                key: f.key,
//                selectionData: '',
//                deselect: false,
//                type: msFilter.filterType === 'attributeSlider' ? DossierFilterType.Range : (f.supportMultiple ? DossierFilterType.MultiSelect : DossierFilterType.Single)
//            };

//            if (f.value === '' || f.value === ReportNoFilterTextValue) {
//                if (f.showSelectAll && f.overideValue) {
//                    const k: IDossierFilterDetailItems = getDossierFilter(dossierFilters, f.externalId).filterDetail.items[0];
//                    if (f.supportMultiple) {
//                        i.selectionData = { selections: [] };
//                    }
//                    else {
//                        i.selectionData = { selection: { name: k.name, value: k.value } };
//                    }
//                }
//                else {
//                    if (f.supportMultiple) {
//                        for (const m of msFilter.filterDetail.items) {
//                            selections.push({ value: m.value });
//                        }
//                    }
//                    else {
//                        i.deselect = true;
//                    }
//                }
//            }
//            else {
//                if (msFilter.filterType === 'attributeSlider') {
//                    const k: IDossierFilterDetailItems[] = getDossierFilter(dossierFilters, f.externalId).filterDetail.items;
//                    const inc: number = f.rangeIncrements || 2;
//                    const end: number = k.findIndex(q => q.value === getDossierFilterValue(msFilter, f));
//                    const start: number = end - inc;
//                    i.selectionData = { selections: [start < 0 ? 0 : start, end < 0 ? k.length : end] };
//                }
//                else {
//                    if (f.supportMultiple) {
//                        const values: string = f.dossier ? f.value : f.msValue;
//                        // selections: [{ name: f.displayValue, value: f.dossier ? f.value : f.msValue }]
//                        for (const i of values.split(/,/)) {
//                            selections.push({ value: i });
//                        }
//                        i.selectionData = selections;
//                    }
//                    else {
//                        i.selectionData = { selection: { name: f.displayValue, value: f.dossier ? f.value : f.msValue } };
//                    }
//                }
//            }
//            s.push(i);
//        }
//    }
//    return s;
//}

//export const applyDossierFilterSelections = (dossier: any, s: IDossierFilterSelection[]) => {
//    for (const c of s) {
//        switch (c.type) {
//            case DossierFilterType.MultiSelect:
//                dossier.filterSearchMultiAttributes({ filterInfo: { key: c.key, }, holdSubmit: true, selections: c.selectionData });
//                break;

//            case DossierFilterType.Range:
//                dossier.filterAttributeMultiSlider({ filterInfo: { key: c.key }, selections: c.selectionData, holdSubmit: true });
//                break;

//            default:
//                dossier.filterSelectSingleAttribute({ filterInfo: { key: c.key, }, holdSubmit: true, selection: c.selectionData });
//                break;
//        }
//    }
//    dossier.filterApplyAll();

//}

export const checkDossierFilters = (i: IDossierFilter[], f: IMSFilter, initialized: boolean) => {
    let update: boolean = false;
    if (i.filter(q => q.filterName.toLowerCase().trim() === f.name.toLowerCase().trim() || q.filterName.toLowerCase().trim() === f.externalId.toLowerCase().trim()).length > 0) {
        const m: IDossierFilter = i.filter(q => q.filterName.toLowerCase().trim() === f.name.toLowerCase().trim() || q.filterName.toLowerCase().trim() === f.externalId.toLowerCase().trim())[0];

        if (m.filterDetail.items.length !== f.values.length) {
            update = true;
        }
        else {
            for (const v of m.filterDetail.items) {
                if (f.values.filter(q => q.id === v.value).length === 0) {
                    update = true;
                }
            }
        }
        if (update) {
            const c: IDropdownValues[] = [];
            for (const v of m.filterDetail.items) {
                c.push({ ...defaultDropdownValues, id: v.value, name: v.name });
            }
            f.values = c;
        }
    }
}
