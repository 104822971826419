import { IDropdownValues } from "../../core/components/form/OEDropdown";
import { IBaseAuditedEntity } from "../../core/entities/BaseAuditedEntity";
import { IBaseList } from "../../core/entities/BaseList";
import { IReport } from "../../reporting/entities/Report";

export const ReportNoFilterTextValue = "ZZZ-ZZZ";

export enum ReportFilterType {
    Dropdown = 'DD',
    Date = 'DA',
    Text = 'TE',
    Number = 'NU',
    MultiSelect = 'MS',
}

export interface IReportBaseFilter extends IBaseAuditedEntity {
    id: string;
    label: string;
    name: string;
    externalId: string;
    showSelectAll: boolean;
    selectAllText: string;
    sortOrder: number;
    returnValues: boolean;
    overrideValue: string;
    dossier: boolean;
    msId: string;
    rangeIncrements?: number;
    ignoreFilter?: boolean;
    noFilterText?: string;
    defaultSelection?: string;
}

export const defaultReportBaseFilter: IReportBaseFilter = {
    id: '', label: '', sortOrder: 0, isSet: false, name: '',
    externalId: '', showSelectAll: true, returnValues: true, overrideValue: '',
    dossier: false, msId: '', selectAllText: '', ignoreFilter: false
};

export interface IReportFilter extends IReportBaseFilter {
    properties: string;
    reportId: string;
    reportFilterTypeId: string;
    value: string;
    defaultValue: string;
    cascading: boolean;
    values: IDropdownValues[];
}

export const defaultReportFilter: IReportFilter = {
    ...defaultReportBaseFilter, properties: '', reportId: '', reportFilterTypeId: '',
    value: '', defaultValue: '', cascading: false, values: [], name: ''
};

export interface IReportFilterSelectionValues {
    name: string;
    values: IDropdownValues[];
}

export const defaultReportFilterValues: IReportFilterSelectionValues = {
    name: '', values: [], 
};

export interface IReportFilterListItem extends IBaseAuditedEntity {
    reportFilter: IReportFilter;
    report: IReport;
    reportFilterType: any;
}

export const getReportFilterList = (i: IBaseList<IReportFilterListItem>): IReportFilter[] => {
    const d: IReportFilter[] = [];
    for (const v of i.items) {
        d.push({
            ...v.reportFilter,
        });
    }
    return sortConfigruationArray(d);
};

export const getJSONFromReportFilter = (p: string): IReportBaseFilter[] => {
    if (p === null) {
        return [];
    }
    try {
        return JSON.parse(p);
    }
    catch {
        return [];
    }
}

export const getJSONForReportFilterSave = (i: IReportBaseFilter[]): string => {
    const j: IReportBaseFilter[] = [];
    for (const p of i) {
        j.push({
            id: p.id,
            label: p.label,
            name: p.name,
            sortOrder: p.sortOrder,
            externalId: p.externalId,
            showSelectAll: p.showSelectAll,
            returnValues: p.returnValues,
            overrideValue: p.overrideValue,
            dossier: p.dossier,
            msId: p.msId,
            selectAllText: p.selectAllText,
            rangeIncrements: p.rangeIncrements,
            ignoreFilter: p.ignoreFilter,
            noFilterText: p.noFilterText,
            defaultSelection: p.defaultSelection
        });
    }
    return JSON.stringify(j);
}
    
const sortConfigruationArray = (d: IReportFilter[]): IReportFilter[] => {
    return d.sort((n1, n2) => {
        if (n1.label > n2.label) {
            return 1;
        }
        if (n1.label < n2.label) {
            return -1;
        }
        return 0;
    });
};

export const getFiltersFromBaseFilters = (s: string, baseFilters: IReportFilter[]): IReportFilter[] => {
    const f: IReportBaseFilter[] = getJSONFromReportFilter(s);
    const v: IReportFilter[] = [];
    for (const c of baseFilters) {
        if (f.filter(q => q.name === c.name).length > 0) {
            const i: IReportBaseFilter = f.filter(q => q.name === c.name)[0];
            v.push({ ...c, dossier: i.dossier, ignoreFilter: i.ignoreFilter, rangeIncrements: i.rangeIncrements, noFilterText: i.noFilterText, defaultSelection: i.defaultSelection, overrideValue: i.overrideValue, selectAllText: i.selectAllText, showSelectAll: i.showSelectAll, value: '' });
        }
    }
    return v;
}

