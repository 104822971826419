

export interface IMicroStrategyDossierFilterSelection {
    name?: string;
    value: string;
}

export const defaultMicroStrategyDossierFilterSelection: IMicroStrategyDossierFilterSelection = {
    value: ''
};

export interface IMicroStrategyDossierFilterInfo {
    key: string;
}

export const defaultMicroStrategyDossierFilterInfo: IMicroStrategyDossierFilterInfo = {
    key: ''
};

export interface IMicroStrategyDossierFilterSingle {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selection: IMicroStrategyDossierFilterSelection;
}

export const defaultMicroStrategyDossierFilterSingle: IMicroStrategyDossierFilterSingle = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selection: defaultMicroStrategyDossierFilterSelection
};

export interface IMicroStrategyDossierFilterMultiple {
    filterInfo: IMicroStrategyDossierFilterInfo;
    holdSubmit?: boolean;
    selections: IMicroStrategyDossierFilterSelection[];
}

export const defaultMicroStrategyDossierFilterMultiple: IMicroStrategyDossierFilterMultiple = {
    filterInfo: defaultMicroStrategyDossierFilterInfo, selections: []
};


