import React, { useEffect, useState } from 'react';
import OEHeading from '../core/components/general/OEHeading';
import OETabs, { OETab } from '../core/components/tabs/OETabs';
import { Icon } from '../core/entities/Icon';
import { refreshPage } from '../core/utilities/URL';
import Menus from './components/Menus';
import Routes from './components/Routes';
import Settings from './components/Settings';
import { IModule } from './entities/Module';
import { defaultRoute } from './entities/Route';
import { useGetUserModules } from './services/RouteService';

export const ModuleComponents = {
    Menus: 'Menus',
    Settings: 'Settings',
    Routes: 'Routes',
};

export interface ISelectedSiteAdminInfo {
    reloadAdmin: () => void;
}

interface ISelectedComponentInfo extends ISelectedSiteAdminInfo {
    name: string;
}


const SelectedComponent: React.FunctionComponent<ISelectedComponentInfo> = ({ name, reloadAdmin }) => {
    const Viewers: any = {};
    Viewers[ModuleComponents.Menus] = Menus;
    Viewers[ModuleComponents.Settings] = Settings;
    Viewers[ModuleComponents.Routes] = Routes;

    const SelectedViewer: React.FunctionComponent<ISelectedSiteAdminInfo> = Viewers[name];
    return (<SelectedViewer reloadAdmin={reloadAdmin} />);
};



const SiteAdmin: React.FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState('Menus');
    const { service, setRoute } = useGetUserModules();
    const [modules, setModules] = useState<IModule[]>([]);

    useEffect(() => {
        setRoute({ ...defaultRoute, path: '/siteadmin ' });
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (service.result) {
            setModules(service.result.items);
        }
    }, [service]);

    const setTab = (event: any) => {
        setActiveTab(event);
    };

    const reloadAdmin = () => {
        refreshPage();
    }

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="Site Administration" icon={Icon.SiteAdministration} />
            {modules.length > 0 && (
                <OETabs defaultTab={activeTab} onSelect={setTab}>
                    {modules.map((item, index) =>
                        <OETab key={index} title={item.name} eventKey={item.component}>
                            <SelectedComponent name={item.component} reloadAdmin={reloadAdmin} />
                        </OETab>
                    )}
                </OETabs>
            )}
        </div>
    );
};

export default SiteAdmin;