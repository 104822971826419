import React from 'react';
import { getFutureDate } from '../../core/utilities/Date';
import { ISiteMenu } from '../entities/SiteMenu';
import '../styles/NavMenu.css';

export const saveRootMenu = (i: ISiteMenu) => {
    localStorage.setItem('nimbleSelectedMenu', i.rootId.toString());
}

const SiteFooter: React.FunctionComponent = () => {

    return (
        <div className="site-footer">
            <div className="pull-right m-r-10">
                &copy; {getFutureDate(0).getFullYear()} Otis Educational
                Systems, Inc.&nbsp;
            </div>
        </div>

    );

};

export default SiteFooter;
