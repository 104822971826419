import React, { Children } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Icon } from '../../entities/Icon';
import { displayHTMLContent } from '../../utilities/Miscellaneous';
import OELink from './OELink';

export enum ModalSize {
    Large = 'xl',
    Medium = 'lg',
    Small = 'sm'
}

interface IOEModalInfo {
    id?: string;
    show?: boolean;
    onHide?: () => void;
    className?: string;
    notPersistent?: boolean;
    oeSize?: ModalSize;
    title?: string;
    icon?: string;
    children?: any;
}

const OEModal: React.FunctionComponent<IOEModalInfo> = ({ className, id, show, onHide, notPersistent, children, oeSize, title, icon }) => {

    const hideModal = () => {
        onHide && onHide();
    };

    return (
        <Modal
            id={id}
            backdrop={!notPersistent ? 'static' : false}
            className={className}
            centered={true}
            show={show}
            size={oeSize || ModalSize.Large}
            enforceFocus={false}
            onHide={hideModal}
        >
            {title && (
                <OEModalHeader onHide={onHide} title={title} icon={icon} />
            )}
            {Children.map(children, i => i)}
        </Modal>
    );
};

interface IOEModalHeaderInfo {
    id?: string;
    onHide?: () => void;
    title?: string;
    icon?: string;
    className?: string;
    children?: any;
}

export const OEModalHeader: React.FunctionComponent<IOEModalHeaderInfo> = ({ className, id, onHide, children, title, icon }) => {

    return (
        <Modal.Header
            id={id}
            className={className}
        >
            {Children.map(children, i => i)}
            {title && (
                <OEModalTitle title={title} icon={icon} />
            )}
            <OELink icon={Icon.Close} style={{ color: '#BBB', marginRight: '-10px' }} onClick={onHide} />
        </Modal.Header>
    );
};

interface IOEModalTitleInfo {
    id?: string;
    className?: string;
    icon?: string;
    title?: string;
    children?: any;
}

export const OEModalTitle: React.FunctionComponent<IOEModalTitleInfo> = ({ className, id, children, icon, title }) => {
    return (
        <Modal.Title
            id={id}
            style={{ width: '100%' }}
            className={`${className || ''} m-b-0 h6`}
        >
            <>
                {icon && (
                    <i className={`${icon} pull-left`} />
                )}
                {title &&
                    <div dangerouslySetInnerHTML={displayHTMLContent(title)} />
                }
                {Children.map(children, i => i)}
            </>
        </Modal.Title>
    );
};

interface IOEModalBodyInfo {
    id?: string;
    className?: string;
    children?: any;
}

export const OEModalBody: React.FunctionComponent<IOEModalBodyInfo> = ({ className, id, children }) => {

    return (
        <Modal.Body
            id={id}
            className={className}
        >
            {Children.map(children, i => i)}
        </Modal.Body>
    );
};

interface IOEModalFooterInfo {
    id?: string;
    className?: string;
    children?: any;
}

export const OEModalFooter: React.FunctionComponent<IOEModalFooterInfo> = ({ className, id, children }) => {

    return (
        <Modal.Footer
            id={id}
            className={className}
        >
            {Children.map(children, i => i)}
        </Modal.Footer>
    );
};

export default OEModal;


