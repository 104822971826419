import React, { useEffect, useState } from 'react';
import OESpinner from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { canDebugReport } from '../../../reporting/entities/Report';
import { ReportProcessingStep, updateProcessingStep } from '../../../reporting/entities/ReportProcessing';
import { validateToken } from '../../services/MicrostrategyDossierService';
declare global { var msLogin: any; }
interface IComponentInfo {
    processingStep: ReportProcessingStep;
    setProcessingStep: (i: ReportProcessingStep) => void;
    setNotification: (n: INotification) => void;
}
const defaultWaitMessage: string = 'One moment please...'

const MSLogin: React.FunctionComponent = () => {
    return (
        <>
            <iframe style={{ width: `0`, height: `0`, }} title="Login Check"
                src={`${localStorage.getItem('msPath')}/auth/login-dialog.jsp?loginMode=4194304`}
            />
        </>
    );
};

export const TokenValidation: React.FunctionComponent<IComponentInfo> = ({
    setNotification, processingStep, setProcessingStep
}) => {

    const [debug] = useState<boolean>(canDebugReport());
    const [invalid, setInvalid] = useState<boolean>(false);
    const [waitMessage, setWaitMessage] = useState<string>(defaultWaitMessage);

    useEffect(() => {
        switch (processingStep) {
            case ReportProcessingStep.CheckToken:
                updateProcessingStep(ReportProcessingStep.ValidatingToken, setProcessingStep);
                break;

            case ReportProcessingStep.ValidatingToken:
                validateToken(validToken);
                debug && setNotification({ message: 'validating token', type: 'info' });
                break;

        }
        // eslint-disable-next-line
    }, [processingStep]);

    const validToken = (e: boolean) => {
        if (e) {
            setWaitMessage(defaultWaitMessage);
            setInvalid(false);
            debug && setNotification({ message: 'token valid', type: 'info' });
            updateProcessingStep(ReportProcessingStep.TokenValid, setProcessingStep);;
        }
        else {
            setWaitMessage(`${waitMessage}.`);
            invalid && msLogin(`${localStorage.getItem('msPath')}/auth/login-dialog.jsp?loginMode=4194304`);
            setInvalid(true);
            debug && setNotification({ message: 'token invalid', type: 'info' });
            window.setTimeout(() => {
                updateProcessingStep(ReportProcessingStep.CheckToken, setProcessingStep);;
            }, 5000); // this setTimeout is a workaround for invalid sessions
        }
    }

    return (
        <>
            <OESpinner message={waitMessage} hidden={processingStep !== ReportProcessingStep.CheckToken && processingStep !== ReportProcessingStep.ValidatingToken} />
            {invalid && (
                <MSLogin />
            )}
        </>
    );
};


export default TokenValidation;
