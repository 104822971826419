import React, { CSSProperties, useEffect, useState } from 'react';

export enum DropdownType {
    String = 1,
    Integer,

}

export interface IDropdownFields {
    id: string;
    name: string;
}

export const defaultDropdownFields: IDropdownFields = {
    id: 'id', name: 'name'
};

const getColumnStyle = (width: string): CSSProperties => {
    return {
        width: `${width}`
    };
};

export interface IDropdownValues {
    id: any;
    name: any;
    parentId?: any;
}

export const defaultDropdownValues: IDropdownValues = {
    id: '', name: '', parentId: ''
};

export const getDefaultSelection = (name: string): IDropdownValues => {
    return { ...defaultDropdownValues, id: '', name: `{ ${name} }` };
};

export const getDefaultIntegerSelection = (name: string): IDropdownValues => {
    return { ...defaultDropdownValues, id: -1, name: `{ ${name} }` };
};

interface IDropdown {
    value?: any;
    name?: string;
    values: any[];
    fields?: IDropdownFields;
    type?: DropdownType;
    disabled?: boolean;
    autoFocus?: boolean;
    className?: string;
    placeholder?: string;
    onChange?: (content: any) => void;
    setFieldValue?: (id: any, data: any) => void;
    defaultSelect?: IDropdownValues;
    alwaysShowDefault?: boolean;
    width?: string;
    ariaLabel?: string;
}

export const defaultDropdown: IDropdown = {
    values: []
};

const OEDropdown: React.FunctionComponent<IDropdown> = ({
    value, width, values, type, name, onChange, className, autoFocus,
    disabled, setFieldValue, defaultSelect, alwaysShowDefault, fields, ariaLabel
}: IDropdown) => {

    const [items, setItems] = useState<any[]>([]);
    const [names] = useState<IDropdownFields>(fields || defaultDropdownFields);

    useEffect(() => {
        setItems(values);
        //eslint-disable-next-line
    }, [values]);

    const handleChange = (event: any) => {
        const v = type === DropdownType.Integer ? parseInt(event.target.value, 10) : event.target.value;
        name && setFieldValue?.(name, v);
        onChange?.(v);
    };

    return (
        <>
            <select
                value={value}
                onChange={handleChange}
                className={`${className || ''} form-select`}
                aria-label={ariaLabel ? ariaLabel : undefined}
                autoFocus={autoFocus}
                disabled={disabled || false}
                style={getColumnStyle(width || '')}
            >
                {defaultSelect && (alwaysShowDefault || value === defaultSelect.id) && (
                    <option value={defaultSelect.id}>{defaultSelect.name}</option>
                )}
                {items && items.length > 0 && items.map((item: any, index: number) =>
                    <option value={item[names.id]} key={index}>{item[names.name]}</option>
                )}
            </select>
        </>
    );
};

export default OEDropdown;

export const defaultDropdownSelect: IDropdownValues = { ...defaultDropdownValues, id: '', name: '{ Please Select }' };
export const defaultDropdownIntegerSelect: IDropdownValues = { ...defaultDropdownValues, id: 0, name: '{ Please Select }' };

export const defaultDropdownSelectAll: IDropdownValues = { ...defaultDropdownValues, id: '', name: '{ All }' };
export const defaultDropdownIntegerSelectAll: IDropdownValues = { ...defaultDropdownValues, id: -1, name: '{ All }' };

export const defaultDropdownSelectNone: IDropdownValues = { ...defaultDropdownValues, id: '', name: '{ None }' };
export const defaultDropdownIntegerSelectNone: IDropdownValues = { ...defaultDropdownValues, id: 0, name: '{ None }' };
