import { useEffect, useState } from 'react';
import { processPostRequest } from '../../core/services/PostRequest';
import { IUserMessageAlert } from '../entities/UserMessageAlert';
import { useGetAlerts, usePostAlertMarkAsRead } from '../services/UserMessageAlertService';
import { JSX } from 'react/jsx-runtime';
import '../../css/alertWidget.css';
import { useNavigate } from "react-router-dom";

const UserMessageAlertsList: React.FunctionComponent = () => {
    const maxDisplayCount = 5;
    const navigate = useNavigate();

    const { service: getAlertService } = useGetAlerts();
    const { service: postUrlService,
        setAlert: urlPostAlertMarkRead,
        setAlerts: setPostAlertsCollection,
        totalItemCount: totalAlertsCount,
        setTotalItemCount: setTotalAlertsCount
    } = usePostAlertMarkAsRead();

    const [alerts, setAlerts] = useState<IUserMessageAlert[]>([]);

    const onclickMarkAsRead = (alert: IUserMessageAlert) => {
        setPostAlertsCollection(alerts);
        urlPostAlertMarkRead(alert);
    }

    useEffect(() => {
        processPostRequest({
            service: usePostAlertMarkAsRead,
            errorMessage: 'Error occurred updating Alert',
        });
    }, [postUrlService]);

    useEffect(() => {
        if (getAlertService.result) {
            setAlerts(getAlertService.result.items);
            setTotalAlertsCount(getAlertService.result.items.filter(e => !e.isRead).length);
        }
    }, [getAlertService, setTotalAlertsCount]);

    const GetDateTime = (dateTime: string) => {
        if (dateTime === "") {
            return "";
        }
        var date = new Date(dateTime);
        return `${date.toLocaleDateString('en-US')} ${date.toLocaleTimeString()}`;
    }

    const loadCogIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-gear" viewBox="0 0 16 16">
        <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0" />
        <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z" />
    </svg>;

    const redirectToNotificationPage = () => {
        navigate("../userprofile?tab=messageHistory");
    }

    const generateRows = () => {
        var iteration = 0;
        var list: JSX.Element[] = [];
        alerts.map((item, index) => {
            if (!item.isRead && iteration < maxDisplayCount) {
                iteration++;
                list.push(<tr key={index}>
                    <td>
                        <ul className="table_outer_ul">
                            <li>
                                <div className='bar'></div>
                            </li>
                            <li>
                                <ul className="table_inner_ul">
                                    <li>
                                        <span className='time'>{GetDateTime(item.creationTime)}</span>
                                    </li>
                                    <li>
                                        {item.body}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </td>
                    <td>
                        <div className='buttonContainer'>
                            <button className="btn-xs btn-danger" onClick={() => onclickMarkAsRead(item)}>dismiss</button>
                        </div>
                    </td>
                </tr>);
            }
            return item;
        });
        return list;
    }

    if (totalAlertsCount > 0) {
        return (
            <div className="alertWidgetContainer">
                <div className='row'>
                    <div className='col-9'>
                        <h4 className="header">Messages ({totalAlertsCount}) </h4>
                        <span className="header note">NOTE: Messages do not exceed more than {maxDisplayCount} rows. Dismiss messages to view more.</span>
                    </div>
                    <div className='col-3'>
                        <button className="notificationSettings" onClick={() => redirectToNotificationPage()}>
                            {loadCogIcon()}
                            Notification Settings</button>
                    </div>
                </div>
                <table>
                    <tbody>
                        {generateRows()}
                    </tbody>
                </table>
            </div>
        );
    }
    else
        return <></>
};

export default UserMessageAlertsList;