import { useEffect, useState } from 'react';
import { IBaseList } from '../../core/entities/BaseList';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { IReportFilterListItem } from '../../report-microstrategy/entities/ReportFilter';
import { ReportType } from '../entities/ReportType';

export const useGetEmbeddedReportFilters = (b: boolean) => {
    const [service, setService] = useState(GetRequest.empty<IBaseList<IReportFilterListItem>>());
    const [refresh, setRefresh] = useState(b);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/report-filters/?MaxResultCount=1000&ReportId=${ReportType.EmbeddedFilters}`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

