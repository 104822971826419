import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FormInputType } from '../../core/components/formik/entities/Form';
import OEFormEditor from '../../core/components/formik/OEFormEditor';
import OEFormik from '../../core/components/formik/OEFormik';
import OEFormInput from '../../core/components/formik/OEFormInput';
import { ProgressMessages } from '../../core/components/messaging/enums/ProgressMessages';
import { SuccessMessages } from '../../core/components/messaging/enums/SuccessMessages';
import { defaultWidget, getWidget, IWidget } from '../entities/Widget';
import { WidgetTypeCode } from '../entities/WidgetType';
import { useGetWidget, usePutWidget } from '../services/WidgetService';

const labelColumns: number = 12;

interface IFormInfo {
    onCancel: () => void;
}

const Form: React.FunctionComponent<FormikProps<IWidget> & IFormInfo> = ({ values, errors, touched, setFieldValue }) => {
    return (
        <>
            {values.widgetType.code === WidgetTypeCode.Text && (
                <OEFormInput
                    label="Content" name="content" value={values.content}
                    errors={errors} touched={touched} columns={labelColumns}
                    required={true} setFieldValue={setFieldValue} inputType={FormInputType.String}
                />
            )}
            {values.widgetType.code === WidgetTypeCode.HTMLContent && (
                <OEFormEditor
                    label="" name="content" value={values.content}
                    errors={errors} touched={touched}
                    columns={labelColumns} setFieldValue={setFieldValue}
                />
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IWidget>().shape({
});

interface IFormikInfo {
    widgetId: string;
    onCancel: () => void;
    onSave: () => void;
}

const WidgetFormik: React.FunctionComponent<IFormikInfo> = ({ widgetId, onCancel, onSave }) => {

    const { service, setItem: setSaveItem } = usePutWidget();
    const { service: getService, setWidgetId } = useGetWidget();
    const [item, setItem] = useState<IWidget>(defaultWidget);

    useEffect(() => {
        if (getService.result) {
            setItem(getWidget(getService.result));
        }
    }, [getService]);

    useEffect(() => {
        setWidgetId(widgetId);
    }, [widgetId, setWidgetId]);

    return (
        <OEFormik
            item={item}
            labelColumns={labelColumns}
            progressMessage={`${ProgressMessages.DefaultProgress} ${item.name}`}
            successMessage={` ${item.name} ${SuccessMessages.DefaultSuccess}`}
            onSave={onSave}
            validationSchema={ValidationScheme}
            submitText="Save"
            component={Form}
            onCancel={onCancel}
            setItem={setSaveItem}
            service={service}
        />
    );
};

export default WidgetFormik;
