import React, { useEffect, useState } from 'react';
import { IFilter } from '../core/components/filter/entities/Filter';
import OEFilter from '../core/components/filter/OEFilter';
import OEHeading from '../core/components/general/OEHeading';
import { ColumnType, IColumn, ModifiedColumns } from '../core/components/table/entities/Column';
import { IColumnAction } from '../core/components/table/entities/ColumnAction';
import OETable from '../core/components/table/OETable';
import { Icon } from '../core/entities/Icon';
import UserFormik from './components/admin/UserFormik';
import UserPermissions from './components/admin/UserPermissions';
import { defaultUser, getUserList, IUser } from './entities/User';
import { useGetUsers } from './services/UserService';

enum ModalTypes {
    None = 1,
    Edit,
    Permissions,
}

const UserAdmin: React.FunctionComponent = () => {

    const { service, doRefresh } = useGetUsers();

    const [items, setItems] = useState<IUser[]>([]);
    const [filterList, setFilterList] = useState<IUser[]>([]);
    const [item, setItem] = useState<IUser>(defaultUser);
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);

    useEffect(() => {
        if (service.result) {
            setItems(getUserList(service.result));
        }
    }, [service]);

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onSave = () => {
        setShowModal(ModalTypes.None);
        doRefresh();
    };

    const onEdit = (i: IUser) => {
        setItem(i);
        setShowModal(ModalTypes.Edit);
    };

    const onUpdateSecurity = (i: IUser) => {
        setItem(i);
        setShowModal(ModalTypes.Permissions);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Permissions, onClick: onUpdateSecurity, helpText: 'Update Permissions' },
    ];
    const columns: IColumn[] = [
        { actions, id: 'Actions', width: '20px', name: '', sort: false, type: ColumnType.Actions, className: 'text-center' },
        { id: 'userName', idNewLine: 'id', name: 'Username', sort: true, type: ColumnType.Link, onClick: onEdit },
        { id: 'name', name: 'First Name', sort: true, type: ColumnType.String },
        { id: 'surname', name: 'Last Name', sort: true, type: ColumnType.String },
        { id: 'email', name: 'Email', sort: true, type: ColumnType.String },
        ...ModifiedColumns,
    ];

    const filter: IFilter = {
        name: 'userAdmin',
        autoSearch: true,
        filters: [
            { name: 'keyword', columns: ['userName', 'email', 'name', 'surname'], autoSearchLength: 3, autoSearch: true, label: 'Keyword', width: 500, placeholder: 'Search by username, email, first name, last name' },
        ],
    };

    return (
        <div className="container">
            <OEHeading className="m-b-20 m-t-10" text="User Administration" icon={Icon.UserManagement} />
            <OEFilter singleLine={true} refresh={doRefresh} className="m-t-0" items={items} filter={filter} setFilterList={setFilterList} />
            <OETable
                data={filterList}
                columns={columns}
                showPagination={true}
                defaultSort="textCol"
                defaultPageSize={25}
                noDataMessage={`No users exists.`}
            />

            {showModal === ModalTypes.Edit && (
                <UserFormik onCancel={onCancel} onSave={onSave} item={item} />
            )}

            {showModal === ModalTypes.Permissions && (
                <UserPermissions onCancel={onCancel} item={item} />
            )}
        </div>
    );
};

export default UserAdmin;