import React, { useEffect, useState } from 'react';
import OEDropdown, { IDropdownValues, defaultDropdownValues } from '../../core/components/form/OEDropdown';
import { IReportFilter } from '../../report-microstrategy/entities/ReportFilter';

interface IComponentInfo {
    filter: IReportFilter;
    setValue: (i: IReportFilter) => void;
}

const Filter: React.FunctionComponent<IComponentInfo> = ({ filter, setValue }) => {
    const [values, setValues] = useState<IDropdownValues[]>([]);
    const [localValue, setLocalValue] = useState<string>(filter.value);

    useEffect(() => {
        const d: IDropdownValues[] = [];
        if (filter && filter.values) {
            for (const c of filter.values) {
                d.push({ ...defaultDropdownValues, id: c.id, name: c.name })
            }
            if (JSON.stringify(d) !== JSON.stringify(values)) {
                setValues([...d]);
                if (filter.values.filter(q => q.id === filter.selectedValue).length > 0) {
                    updateLocalValue(filter.values.filter(q => q.id === filter.selectedValue)[0].id, false);
                }
                else {
                    updateLocalValue(d[0].id, false);
                }
            }
        }
        // eslint-disable-next-line
    }, [filter.values]);

    const updateLocalValue = (i: string, refreshData: boolean) => {
        localStorage.setItem(`FormFilter-${filter.name}`, i);
        setLocalValue(i);
    }

    const updateValue = (i: string) => {
        if (i !== filter.value) {
            setValue({ ...filter, value: i });
            updateLocalValue(i, true);  
        }
    }

    return (
        <>
            {filter.values.length > 0 && (
                <OEDropdown
                    onChange={updateValue}
                    value={localValue}
                    ariaLabel={filter.name}
                    placeholder={filter.label}
                    values={values} />
            )}
        </>
    );
};

export default Filter;
