import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { defaultDropdownSelect } from '../../../core/components/form/OEDropdown';
import { OEInputType } from '../../../core/components/form/OEInput';
import { FormInputType } from '../../../core/components/formik/entities/Form';
import OEForm from '../../../core/components/formik/OEForm';
import OEFormBoolean from '../../../core/components/formik/OEFormBoolean';
import OEFormDropdown from '../../../core/components/formik/OEFormDropdown';
import OEFormEditor from '../../../core/components/formik/OEFormEditor';
import OEFormInput from '../../../core/components/formik/OEFormInput';
import OEHeading from '../../../core/components/general/OEHeading';
import OEModal, { ModalSize, OEModalBody } from '../../../core/components/general/OEModal';
import { getReportConfigurationDefaultValue, getReportConfigurationDisplayValue, getReportConfigurationValues, IReportConfigurationItem, ReportConfigurationDataType } from '../../entities/ReportConfiguration';

const labelColumns: number = 2;

interface IFormInfo {
    lookupTables: IReportConfigurationItem[];
}

const Form: React.FunctionComponent<FormikProps<IReportConfigurationItem> & IFormInfo> = ({ values, errors, touched, setFieldValue, lookupTables }) => {

    const setConfigurationType = (i: any) => {
        const s: IReportConfigurationItem = lookupTables.filter(q => q.id === i)[0];
        setFieldValue('id', s.id);
        setFieldValue('dataType', s.dataType);
        setFieldValue('name', s.name);
        setFieldValue('value', getReportConfigurationDefaultValue(s.id));
    }

    const setConfigurationValue = (i: any) => {
        const s: IReportConfigurationItem = lookupTables.filter(q => q.id === values.id)[0];
        setFieldValue('value', i);
        setFieldValue('displayValue', getReportConfigurationDisplayValue({ ...s, value: i }));
    }

    return (
        <>
            <OEFormDropdown
                label="Type" name="id" value={values.id}
                alwaysShowDefault={true} defaultSelect={defaultDropdownSelect}
                errors={errors} touched={touched} columns={labelColumns} values={lookupTables}
                onChange={setConfigurationType} required={true}
            />

            {values.dataType === ReportConfigurationDataType.Dropdown && (
                <OEFormDropdown
                    label="Value" name="value" value={values.value}
                    defaultSelect={defaultDropdownSelect} alwaysShowDefault={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    values={getReportConfigurationValues(values.id)}
                    onChange={setConfigurationValue} required={true}
                />
            )}

            {values.dataType === ReportConfigurationDataType.String && (
                <OEFormInput
                    label="Value" name="value" value={values.value} required={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue} inputType={FormInputType.String} type={OEInputType.Text}
                />
            )}

            {values.dataType === ReportConfigurationDataType.Integer && (
                <OEFormInput
                    label="Value" name="value" value={values.value} required={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue} inputType={FormInputType.Number}
                />
            )}

            {values.dataType === ReportConfigurationDataType.Boolean && (
                <OEFormBoolean
                    label="Value" name="value" value={values.value} required={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue}
                />
            )}

            {values.dataType === ReportConfigurationDataType.Html && (
                <OEFormEditor
                    label="Value" name="value" value={values.value} required={true}
                    errors={errors} touched={touched} columns={labelColumns}
                    setFieldValue={setFieldValue}
                />
            )}
        </>
    );
};

const ValidationScheme = Yup.object<IReportConfigurationItem>().shape({
    value: Yup.string().required('Value is required').nullable(),
});

interface IFormikInfo {
    item: IReportConfigurationItem;
    isEditing: boolean;
    onCancel: () => void;
    onSubmit: (i: IReportConfigurationItem) => void;
    configuration: IReportConfigurationItem[];
}

const ConfigurationFormik: React.FunctionComponent<IFormikInfo> = ({ item, isEditing, onCancel, onSubmit, configuration }) => {

    const [localValues] = useState(item);

    const getForm = (props: FormikProps<any>, p: boolean) => (
        <OEForm {...props}
            onCancel={onCancel}
            component={Form}
            submitText="Submit"
            cancelText="Cancel"
            lookupTables={configuration}
            labelColumns={2}
        />
    );

    return (
        <OEModal show={true} oeSize={ModalSize.Medium} onHide={onCancel}>
            <OEModalBody className="alert-light">
                <OEHeading size={3} text="Add Report Configuration" />
                <Formik
                    onSubmit={onSubmit}
                    initialValues={localValues}
                    enableReinitialize={true}
                    validationSchema={ValidationScheme}
                >
                    {(props) => getForm(props, isEditing)}
                </Formik>

            </OEModalBody>
        </OEModal >
    );
};

export default ConfigurationFormik;
