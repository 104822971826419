import { useEffect, useState } from 'react';
import { GetRequest, processGetAsync } from '../../core/services/GetRequest';
import { PostRequest, processPostAsync } from '../../core/services/PostRequest';
import { defaultSiteReport, ISiteReport } from '../entities/SiteReport';
import { IViewReport, defaultViewReport } from '../entities/ViewReport';

export const useGetSiteReports = () => {
    const [service, setService] = useState(GetRequest.empty<ISiteReport[]>());
    const [refresh, setRefresh] = useState(true);
    const doRefresh = () => setRefresh(true);

    useEffect(() => {
        if (!refresh) { return; }
        processGetAsync(setService, `/api/app/site-reports`);
        setRefresh(false);
    }, [refresh]);

    return { service, doRefresh };
};

export const useGetSiteReport = (Id: string) => {
    const [service, setService] = useState(GetRequest.empty<ISiteReport>());
    const [id, setId] = useState<string>(Id);

    useEffect(() => {
        if (id === '') { return; }
        processGetAsync(setService, `/api/app/site-reports/${id}`);
        setId('');
    }, [id]);

    return { service, setId };
};

export const useGetSiteReportFilters = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [report, setReport] = useState<ISiteReport>(defaultSiteReport);

    useEffect(() => {
        if (report.id === '') { return; }
        processPostAsync(setService, `/api/app/site-reports/filters`, report);
        setReport(defaultSiteReport);
    }, [report]);

    return { service, setReport };
};

export const useViewSiteReport = () => {
    const [service, setService] = useState(PostRequest.empty());
    const [report, setReport] = useState<IViewReport>(defaultViewReport);

    useEffect(() => {
        if (report.id === '') { return; }
        processPostAsync(setService, `/api/app/site-reports/view-report`, report);
        setReport(defaultSiteReport);
    }, [report]);

    return { service, setReport };
};
