import React from 'react';
import OEInput, { OEInputType } from '../form/OEInput';
import { FormInputType } from './entities/Form';
import OEFormItem, { IOEFormItemInfo } from './OEFormItem';
import {
    getFormControlTouched,
    getFormValue,
} from '../../utilities/FormHelper';

interface IComponentInfo {
    inputType: FormInputType;
    type?: any;
    maxLength?: number;
    placeholder?: string;
    value: any;
    rows?: number;
    instructions?: string;
    children?: any;
}

const OEFormInput: React.FunctionComponent<
    IComponentInfo & IOEFormItemInfo
> = ({
    columns,
    name,
    label,
    errors,
    touched,
    setFieldValue,
    required,
    disabled,
    hidden,
    autoFocus,
    autoComplete,
    inputType,
    type,
    maxLength,
    placeholder,
    value,
    rows,
    instructions,
    onChange,
    action,
    actionIcon
}) => {
        return (
            <OEFormItem
                columns={columns}
                name={name}
                label={label}
                errors={errors}
                touched={touched}
                required={required}
                hidden={hidden}
                action={action}
                actionIcon={actionIcon}
            >
                <>
                    {inputType === FormInputType.Label && <span>{value}</span>}
                    {inputType === FormInputType.String && (
                        <OEInput
                            autoComplete={autoComplete}
                            className={`${getFormValue(errors, name) &&
                                getFormControlTouched(touched, name)
                                ? 'is-invalid'
                                : ''
                                } `}
                            autoFocus={autoFocus}
                            type={type}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            disabled={disabled}
                            name={name}
                            rows={rows}
                            value={value}
                            onChange={onChange}
                            setFieldValue={setFieldValue}
                        />
                    )}
                    {inputType === FormInputType.Number && (
                        <OEInput
                            autoFocus={autoFocus}
                            className={
                                getFormValue(errors, name) &&
                                    getFormControlTouched(touched, name)
                                    ? 'is-invalid'
                                    : ''
                            }
                            disabled={disabled}
                            name={name}
                            type={OEInputType.Number}
                            onChange={onChange}
                            value={value}
                            setFieldValue={setFieldValue}
                        />
                    )}
                    {instructions && (
                        <>
                            <i>
                                <small>{instructions}</small>
                            </i>
                        </>
                    )}
                </>
            </OEFormItem>
        );
    };

export default OEFormInput;
